.report-display-page {
    text-align: start;
    margin-top: -5.8em;
    margin-left: 2%;
    text-align: start;
    color: #3D3D3D;
    letter-spacing: -0.015em;

}

ul {
    list-style-type: none;
}

.report-display-page img {
    width: 500px;
    height: 400px;
}

.report-display-page h4 {
    font-size: 1.1em;
}

.report-display-page p {
    font-style: normal;
    font-weight: normal;
    color: #3A3A32;
    margin-top: 1%;
}

.report-display-page__title {
    line-height: 2em;
}
  
.report-display-page__title h1 {
    font-style: normal;
    width: 30em;
    font-weight: bold;
    color: #3D3D3D;
}

.report-display-page__project__details p {
    line-height: 0.7em;
    font-size: 1.1em;
    color: #3A3A32;
    font-style: normal;
    font-weight: 500;
}

.flood-risk-report__satellite__details__image p {
    line-height: 0.4em;
}

.line {
    width: 90%;
    color: #7c9473b0;
    margin-left: 0;
}

.open-legend {
    position: absolute;
    right: 0;
    margin-right: 10%;
}

.open-legend button {
    all: unset;
    padding: 0.3em 1.2em 0.3em 1.2em;
    right: 0;
    background: #C4C4C4;
    font-style: normal;
    font-weight: 500;
}

.flood-risk-report__runoff__retention__details, .flood-risk-report__lulc__details__summary, .flood-risk-report__built__infrastructure__details {
    position: absolute; 
    right: 0;
    margin-right: 10%;
    border: 0.1rem solid #7C9473;
    padding: 1em;
    margin-top: -30em;
}

.close-btn {
    all: unset;
    font-size: 1.8em;
    text-align: end;
    font-weight: 100;
    right: 0;
    margin-right: 8px;
    top: 2px;
    position: absolute;
    color: #3A3A32;
}

.legend-labels li {
    line-height: 30px;
}

.legend-labels li span {
    display: block;
    float: left;
    height: 16px;
    width: 30px;
    margin-right: 5px;
    margin-left: 0;
    border: 2px solid rgb(0, 0, 0);
}
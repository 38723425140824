html,
body {
  height: 100%;
  margin: 0;
}

.website-body {
  display: flex;
  padding-top: 3.5rem;
  flex-flow: column;
  height: 100%;
}

.nav-bar {
  flex: 0 1 auto;
}

.website-webpage {
  flex: 1 1 auto;
  margin-bottom: 5rem;
}

.footer-bar {
  display: flex;
  flex: 0 1 40px;
}
.mapbox-container{
  position: absolute;
width: 1440px;
height: 1336px;
left: 0px;
top: 64px;

}

.openimage button{
  display: flex;
    flex-direction: row;
    align-items: center;
    padding: 1px 17px;
    position: absolute;
    width: 204px;
    height: 36px;
    left: -8px;
    top: 73px;
    background:white;
    border: 2px solid #000000;
    box-sizing: border-box;
    border-radius: 15px;
    flex: none;
    order: 0;
    flex-grow: 0;
    margin: 0px 1156px;
    z-index: 29;
}

.openimage button:hover {
  background: #357960;
  cursor: pointer;
  color: #E5E4CC;
}

.satellite-image-display__details {
  display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 2px;
    position: fixed;
    width: 416px;
    height: 665.45px;
    background: #FFFFFF;
    border: 1px solid #3A3A32;
    box-sizing: border-box;
    left: 800px;
    z-index: 10;
    top:1px;
}
.satellite-image-display__details-frame {
  display: flex;
flex-direction: column;
align-items: center;
padding: 0px;

position: static;
left: 1.92%;
right: 1.92%;
top: 1.2%;
bottom: 1.2%;

/* backdrop-filter: blur(4px); */
/* Note: backdrop-filter has minimal browser support */


/* Inside auto layout */

flex: none;
order: 0;
flex-grow: 0;
margin: 0px 10px;
}

.satellite-image-display__details__header {
  display: flex;
flex-direction: row;
justify-content: center;
align-items: center;
padding: 8px;

position: static;
width: 400px;
height: 44px;
left: 0px;
top: 192px;


/* Inside auto layout */

flex: none;
order: 2;
align-self: stretch;
flex-grow: 0;
margin: 0px 0px;
}

.satellite-image-display__details__properties{
  font-size: 1.1em;
  font-style: normal;
  font-weight: normal;
  padding: 0.5rem;
  letter-spacing: -0.015em;
  line-height: 22px;
  color: #3A3A32;
}


.default-button {
  display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 8px;
    position: relative;
    width: 98px;
    height: 64px;
    left: 6px;
    top: -655px;
    background: rgba(229, 244, 228, 0.5);
    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;
    margin: 0px 0px;
    z-index: 5;
    cursor: pointer;
    background:white;
    border: black;
}

.remote-sensing-icon2 {
  left: 4.55%;
    right: 4.55%;
    top: 4.55%;
    bottom: 4.55%;
    flex: none;
    order: 0;
    flex-grow: 0;
    margin: 0px 0px;
    Width: 43px;
    Height: 35px;
}

.vegetation-button {
  display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 8px;
    position: relative;
    width: 98px;
    height: 64px;
    left: 6px;
    top: -653px;
    background: rgba(229, 244, 228, 0.5);
    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;
    margin: 0px 0px;
    z-index: 5;
    cursor: pointer;
    background:white;
    border: black;
}


.generate-report-button {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 8px 16px;
  position: absolute;
  width: 175px;
  height: 39px;
  left: 1147px;
  top: 8px;
  background: #E5F4E4;
  border-radius: 10px;
  flex: none;
  order: 0;
  flex-grow: 0;
  z-index: 5;
  margin: 10px 0px;
}

.generate-report-tab {
  display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0px;
    position: relative;
    width: 416px;
    height: 588.22px;
    /* Top: -20px; */
    Left: 829px;
    top: 191px;
    background: #FFFFFF;
    box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
    border-radius: 4px;
    z-index: 30;
}

.details-picture-box2 {
  display: flex;
flex-direction: row;
align-items: flex-start;
padding: 8px;

position: static;
width: 380px;
height: 176px;
left: 112px;
top: 16px;


/* Inside auto layout */

flex: none;
order: 1;
flex-grow: 0;
margin: 0px 0px;
}

.generate-title {
  display: flex;
flex-direction: row;
justify-content: center;
align-items: center;
padding: 8px;

position: static;
width: 400px;
height: 44px;
left: 0px;
top: 20px;

font-family: 'Roboto';
font-style: normal;
font-weight: 700;
font-size: 24px;
line-height: 28px;
text-align: center;
letter-spacing: -0.015em;

color: #3A3A32;
}

.snap-compare {
  display: flex;
flex-direction: row;
align-items: flex-start;
padding: 0px;

position: static;
width: 400px;
height: 64px;
left: 0px;
top: 320.22px;


/* Inside auto layout */

flex: none;
order: 3;
flex-grow: 0;
margin: 4px 0px;
}

.compare{
  display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
padding: 8px;

position: static;
width: 200px;
height: 64px;
left: 200px;
top: 14.5px;


/* Inside auto layout */

flex: none;
order: 1;
align-self: stretch;
flex-grow: 1;
margin: 0px 0px;
font-family: 'Roboto';
font-style: normal;
font-weight: 700;
font-size: 16px;
line-height: 19px;
/* identical to box height */


/* dark surface */

color: #4D4D4D;
border:transparent;
cursor:pointer;
}

.snapshot{
  display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
padding: 0px;

position: static;
width: 200px;
height: 64px;
left: 0px;
top: 0px;

/* light primary */

background: #E5F4E4;

/* Inside auto layout */

flex: none;
order: 0;
align-self: stretch;
flex-grow: 1;
margin: 0px 0px;

font-family: 'Roboto';
font-style: normal;
font-weight: 700;
font-size: 16px;
line-height: 19px;
/* identical to box height */


/* primary */

color: #357960;
border:transparent;
cursor:pointer;

}

.generate-list{
  display: flex;
flex-direction: column;
align-items: flex-start;
padding: 0px;

position: static;
width: 400px;
height: 216px;
left: 0px;
top: 388.22px;


/* Inside auto layout */

flex: none;
order: 4;
flex-grow: 0;
margin: 4px 0px;

}

.list{
  display: flex;
flex-direction: row;
align-items: center;
padding: 16px;

position: static;
width: 400px;
height: 40px;
left: 0px;
top: 176px;

background: #E5F4E4;
border-radius: 4px;

/* Inside auto layout */

flex: none;
order: 4;
flex-grow: 0;
margin: 4px 0px;
font-family: 'Roboto';
font-style: normal;
font-weight: 700;
font-size: 16px;
line-height: 19px;
/* identical to box height */


/* primary */

color: #357960;
cursor:pointer;
border-color:transparent;
}

.list:hover {
  border-color: #357960;
  color: #357960;
   /* font-size: 22px;  */
  border-radius: 7px;
  
  font-weight: 700;
  border-width: 2px;
}
.notif-card-container{
  display: flex;
  flex-direction: column;

  width: 50em;

  background: #E2E2E2;
  border-radius: 5px;
  margin: 1em;
}

.notif-collapse{
  vertical-align: middle;
  overflow: hidden;
  transition: height 0.2s ease-in-out;
}

.notif-content{
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 1em;
}

.notif-title{
  text-align: start;

  font-weight: 500;
  font-size: 16px;
  line-height: 21px;
  color: #3D3D3D;
}

.notif-detail{
  margin: 1em;
  
  text-align: start;
  font-weight: 500;
  font-size: 16px;
  line-height: 21px;
  color: #3D3D3D;
}

.notif-date{
  margin-right: 2em;
  height: 23px;
  text-align: right;
  font-weight: 500;
  font-size: 14px;
  line-height: 30px;
  color: #757575;
}

.notif-newlabel{
  float: left;
  margin-left: 1em;
  height: 23px;
  text-align: left;
  font-weight: 500;
  font-size: 14px;
  line-height: 30px;
  color: #757575;
}

.expand-btn{
  background-color: transparent;
  border: transparent;
  height: 2em;
  width: 2em;
}

.btn-icon{
  max-width: 100%;
  max-width: 100%;
  cursor: pointer;
}

.notifs-collection{
  display: flex;
  flex-flow: column-reverse nowrap;
  justify-content: flex-start;
  align-items: flex-start;
  align-content: flex-start;
  
  /* Box Model */
  width: 100%;
  max-height: 600px;
  /*max-height: 250px;*/
  overflow-y: scroll;
}

.notifs-collection::-webkit-scrollbar {
  width: 10px;
}

/* Track */
.notifs-collection::-webkit-scrollbar-track {
  background: transparent; 
}
 
/* Handle */
.notifs-collection::-webkit-scrollbar-thumb {
  background: #E9E9E9;
  border-radius: 5px;
}

/* Handle on hover */
.notifs-collection::-webkit-scrollbar-thumb:hover {
  background: #c4c4c4; 
}
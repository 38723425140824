.darkened-background {
    position: fixed;
    inset: 0px;
    z-index: 1001; /* footer bar is set to 1000 */
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.6);
}

.mowing-form-description {
    padding: 8px 0px;
    font-size: 16px;
    font-weight: 300;
    text-align: left;
}

.mowing-form-checkbox {
    display: flex;
    flex-direction: row;
    padding: 24px 0px 8px;
    align-items: center;
}

.mowing-form-checkbox-label {
    padding-left: 10px;
    font-size: 14px;
    font-weight: 500;
    color: var(--dark-subtle-surface)
}

.mowing-form-unselected {
    width: 24px;
    height: 24px;
    background-color: var(--light-subtle-surface);
    border-style: none;
    border-radius: 5px;
    box-shadow: 1px 1px 4px 0px rgba(0, 0, 0, 0.25) inset;
}

.mowing-form-selected {
    width: 24px;
    height: 24px;
    background-color: var(--primary);
    border-style: none;
    border-radius: 5px;
    box-shadow: 1px 1px 4px 0px rgba(0, 0, 0, 0.25) inset;
}

.mowing-form-satellite-display {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    position: relative;
    left: -8px;
}

.mowing-form-satellite-image {
    width: 238px;
    height: 238px;
    margin: 8px;
    position: relative;
}

.mowing-form-satellite-image-checkbox {
    width: 50px;
    height: 50px;
    background-color: var(--light-surface);
    border-style: none;
    border-radius: 0px 0px 10px 0px;
    position: absolute;
    top: 0px;
    left: 0px;
}

.mowing-form-satellite-image-checkbox button {
    position: absolute;
    top: 14px;
    left: 14px;
}

.mowing-form-satellite-image-details {
    width: 100%;
    height: 35%;
    padding: 10px;
    background-color: rgba(0, 0, 0, 0.75);
    position: absolute;
    bottom: 0;
    text-align: left;
}

.mowing-form-details-text {
    font-size: 16px;
    font-weight: 300;
    color: var(--light-surface);
    line-height: 22px;
}

.mowing-form-details-text span {
    font-weight: 700;
}

.mowing-form-satellite-image img {
    width: inherit;
    height: inherit;
    background-color: rgba(0, 0, 0, 0.2);
}

.mowing-form-popup-display {
    display: flex;
    flex-direction: column;
    width: 75%;
    height: 75%;
    position: fixed;
    z-index: 1002; /* footer bar is set to 1000 */
    inset: 0;
    margin: auto;
    align-items: center;
}

.mowing-form-popup-display-button {
    position: absolute;
    top: 10px;
    right: 10px;
    width: 15px;
    height: 15px;
    filter: brightness(0) invert(1);
}

.mowing-form-popup-display-image {
    width: 100%;
    max-height: 90%;
}

.mowing-form-popup-display-details {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    width: 100%;
    height: 76px;
    background-color: rgba(0, 0, 0, 0.8);
    font-size: 16px;
    font-weight: 300;
    color: var(--light-surface);
}

.mowing-form-popup-display-details div {
    margin: 0px 10px;
}
.manual-change-detection__left-image {
  width: max-content;
  object-fit: contain;
}

.manual-change-detection__right-image {
  width: max-content;
  object-fit: contain;
}

.manual-change-detection__label {
  text-align: left;
  margin-bottom: 20px;
  font-size: 20px;
  color: var(--dark-surface);
}

.manual-change-detection__page {
  text-align: left;
  margin: 1em 0 1em 5rem;
}

.header-container {
  display: flex;
  align-items: center;
}

.manual-change-detection__page__header {
  margin-bottom: 20px;
  text-align: left;
  display: flex;
  align-items: center;
}

.manual-change-detection__page__header h3 {
  font-size: 30px;
  color: var(--dark-surface);
}

.manual-change-detection__page__second__header {
  margin-bottom: 30px;
  text-align: left;

}

.manual-change-detection__page__second__header h3 {
  font-size: 30px;
  color: var(--dark-subtle-surface);
}

.manual-change-detection__sub-heading {
  display: flex;
  justify-content: center;
  font-size: 24px;
  color: var(--dark-surface);
  margin-bottom: 30px;
}

.manual-change-detection__view-select {
  flex: 1 0 min-content;
  margin-bottom: 10px;
  position: relative;
}

.manual-change-detection__select-subcontainer {
  width: 19.75rem;
  /* height: 2rem; */
  display: grid;
  grid-template-columns: 50px auto;
}

.manual-change-detection__select-subcontainer.right {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

/* .manual-change-detection__select-subcontainer .select__control,
.manual-change-detection__select-subcontainer .basic-single {
  width: 19.75rem;
  min-height: unset;
  height: 2rem;
} */

.manual-change-detection__select-subcontainer .basic-single {
  margin-bottom: 20px;
}

.manual-change-detection__selection {
  display: grid;
  margin-bottom: 1em;
  grid-template-columns: repeat(2, 1fr);
}

.manual-change-detection__select {
  flex: 1 0 min-content;
  object-fit: contain;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.manual-change-detection__details {
  display: flex;
  margin-top: 1em;
  width: 94%;
}

.manual-change-detection__left-details {
  flex: 1 0 min-content;
  object-fit: contain;
  position: relative;
  text-align: left;
}

.manual-change-detection__right-details {
  flex: 1 0 min-content;
  object-fit: contain;
  position: relative;
  text-align: left;
  left: 97px;
}

.download-button{
  height: 40px;
  width: 40px;
  border-radius: 10px;
  background:#FFF;
  cursor: pointer;
  border: none;

}

.field-display {
  display: flex;
  flex-direction: column;
  color: var(--dark-surface);
  margin-left: 20px;
}

.field-display-dropdown {
  resize: none;
  width: auto;
  min-width: 80px;
  /* light surface */
  background: var(--light-surface);
  /* primary */
  border: 1px solid var(--primary);
  border-radius: 10px;
  padding: 2px;
  margin-top: 2px;
}
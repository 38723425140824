.sliderContainer {
  width: 100%;
  height: 95%;
  position: relative;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.sliderHalf {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
}

.items-container {
  z-index: 100;
  position: absolute;
  top: 40%;
  left: 11px;
  display: flex;
  /* flex-wrap: nowrap; */
  flex-direction: column;
  width: 88px;
  border-radius: 4px;
  background: var(--light-surface, #FFF);
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  overflow: hidden;
  border: none;
}

.item-wrapper {
  display: flex;
  flex-direction: column;
  color: var(--dark-surface);
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  line-height: normal;
  font-weight: 500;
  letter-spacing: -0.21px;
}

.item-wrapper>.btn-item {
  display: flex;
  flex-direction: column;
  width: 88px;
  height: fit-content;
  padding: 8px;
  align-items: center;
  cursor: pointer;
}

.item-wrapper>.title-item {
  display: flex;
  flex-direction: column;
  width: 88px;
  height: fit-content;
  padding: 8px;
  align-items: center;
}

.item-wrapper>.btn-item>.logo-img {
  width: 24px;
  height: auto;
  padding-bottom: 8px;
}

.bm-item {
  display: inline-block;

  /* Our sidebar item styling */
  text-decoration: none;
  margin-bottom: 10px;
  color: #d1d1d1;
  transition: color 0.2s;
}

.bm-item:hover {
  color: white;
}

.bm-burger-button {
  position: fixed;
  width: 36px;
  height: 30px;
  left: 36px;
  top: 36px;
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
  background: #373a47;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
  height: 24px;
  width: 24px;
}

/* Color/shape of close button cross */
.bm-cross {
  background: #bdc3c7;
}

/* General sidebar styles */
.bm-menu {
  background: #000;
  padding: 2.5em 1.5em 0;
  font-size: 1.15em;
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
  fill: #373a47;
}

/* Wrapper for item list */
.bm-item-list {
  color: #b8b7ad;
}

/* Styling of overlay */
.bm-overlay {
  background: rgba(0, 0, 0, 0.3);
}

.legend-toggle-button {
  background: white;
  width: 45px;
  height: 45px;
  position: absolute;
  left: 50.2%; /* this is a decimal number so that the button is exactly centred on slider line */
  transform: translateX(-50%);
  z-index: 1001;
  cursor: pointer;
}

.legend-toggle-button::after {
  content: "";
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
  width: 20px;
  height: 20px;
  background: url("../../images/arrow\ icon.png") no-repeat center center;
  background-size: contain;
  z-index: 1002;
}

.legend-toggle-button.collapsed::after {
  transform: translateX(-50%) rotate(180deg);
  bottom: 12px;
}

.legend-image-container {
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1000; 
}

.legend-image {
  width: 450px;
  height: auto;
  display: block;
  margin: 0 auto;
}

.custom-marker {
  background-size: contain; 
  background-position: center;
  background-repeat: no-repeat;
  width: 40px; 
  height: 50px;
}
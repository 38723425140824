.map-container {
    width: 100%;
    height: 100%;
    position: relative;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

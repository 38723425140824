  .gedi-body {
    display: flex;
    flex-direction: row;
  }

  .quit-btn {
    margin-top: 1em;
    border: 3px transparent;
    background: transparent;
    cursor: pointer;
  }

  .gedi-back-col {
    width: 5em;
  }

  .gedi-form-col {
    margin-left: 1.5em;
    margin-right: 1.5em;
    margin-bottom: 3em; 
    width: 70%;
    position: relative;
  }

  .carbon-form-body {
    font-style: normal;
    text-align: start;
    font-weight: bold;
    color: #3A3A32;
    letter-spacing: -0.015em;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }

  .gedi-form-body {
    font-style: normal;
    text-align: start;
    font-weight: bold;
    color: #3A3A32;
    letter-spacing: -0.015em;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }

  .gedi-input-block {
    height: auto;
    overflow: auto;
    margin-bottom: 1em;
    overflow: hidden;  
  }

  .gedi-interpolation {
    height: auto;
    overflow: auto;
    margin-bottom: 1em;
    display: flex;
    align-items: center;
  }

  .gedi-interpolation div {
    font-weight: normal;
    background-color: #fff;
    border: 1px solid black; 
    position: absolute;
    border-radius: 5px;
    padding: 7px;
    z-index: 1;
    left: 190px;
    width: 250px;
    text-align: center;
  }

  .gedi-buffer {
    margin-top: 0.5%;
    font-weight: normal;
  }

  .gedi-buffer img {
    width: 1.4em;
    position: relative;
    left: 5px;
    bottom: 1px;
  }

  .gedi-buffer div {
    font-weight: normal;
    background-color: #fff;
    border: 1px solid black; 
    position: absolute;
    border-radius: 5px;
    padding: 7px;
    z-index: 1;
    left: 300px;
    bottom: 10px; 
    width: 250px;
    text-align: center;
  }

  .gedi-interpolation img {
    width: 1.4em;
    position: relative;
    left: 5px;
    bottom: 1px;
  }

  .carbon-form-body input {
    margin-top: 0.25%;
    margin-right: 1%; 
    vertical-align: top; 
    border-color: #357960;
    border-radius: 5px;
    border-width: 1px;
    border-style: solid;
    padding-top: 15px;
    padding-bottom: 15px;
    padding-right: 10px;
    padding-left: 10px;
  }

  .gedi-form-body input {
    margin-top: 0.5%;
    width: 30%;
    border-color: #357960;
    border-radius: 5px;
    border-width: 1px;
    border-style: solid;
    padding-top: 15px;
    padding-bottom: 15px;
    padding-right: 10px;
    padding-left: 10px;
  }

  .gedi-form label {
    display: inline-block;
    vertical-align: top;
  }

  .gedi-title {
    display: flex;
    align-items: center;
  }

  .gedi-title div {
    position: absolute;
    width: 400px;
    left: 300px;
    background-color: #fff;
    border: 1px solid black;
    border-radius: 5px;
    padding: 7px;
    z-index: 1;
  }

  .gedi-title img {
    width: 2em;
    position: relative;
    right: 60px;
  }

  .gedi-title h1 {
    padding: 20px 0px;
    font-style: normal;
    width: 10em;
    font-weight: 700;
    letter-spacing: -0.015em;
    color: #3D3D3D;
    border-color: antiquewhite;
    text-align: left;
    font-size: 2em;
  }

  .color_box {
    background-color: #357960
  }

  .gedi-warning-text {
    color: red;
    width: 40%;
  }

  .gedi-warning-text2 {
    color: red;
    width: 40%;
    text-align: left;
  }

  .gedi-button {
    text-align: center;
    position: relative;
    background: #357960;
    width: 12em;
    border-radius: 0.4em;
    border: none;
    font-weight: 700;
    color: #fff;
    padding: 0.5em;
    margin-right: 10px;
    float: left;
  }

  .gedi-button:hover {
    background: #798777;
    cursor: pointer;
    color: #E5E4CC;
  }

  .gedi-button-disabled {
    text-align: center;
    position: relative;
    background: #818280;
    width: 12em;
    border-radius: 0.4em;
    border: none;
    font-weight: 700;
    color: #40403d;
    padding: 0.5em;
    margin-right: 10px;
    float: left;
  }

  .form-input-const {
    margin-top: 0.5%;
    font-weight: normal;
  }

  .gedi-button-box {
    width: 250px
  }
  
  .loader {
    margin-top: 2px;
    width: 30px;
    height: 30px;
    border: 3px solid #515151;
    border-bottom-color: transparent;
    border-radius: 50%;
    display: inline-block;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
    }

    .loader-2 {
      margin-top: 2px;
      width: 30px;
      height: 30px;
      border: 3px solid #515151;
      border-bottom-color: transparent;
      border-radius: 50%;
      display: inline-block;
      box-sizing: border-box;
      animation: rotation 1s linear infinite;
      }

    

    @keyframes rotation {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
    } 

    .hidden {
      visibility: hidden;
    }

    .map-elem {
      height: 300px
    }
.report-creation {
  width: 80%;
  margin-top: -5.8em;
  margin-left: 2%;
  text-align: start;
}

.report-creation__type-form {
  line-height: 2em;
}

.report-creation__title h1 {
  font-style: normal;
  width: 30em;
  font-weight: bold;
  letter-spacing: -0.015em;
  text-align: left;
  color: #3D3D3D;
}

.report-creation p, .report-creation__type-form p{
  letter-spacing: -0.015em;
  line-height: 1em;
  color: #3A3A32;
  font-style: normal;
  font-weight: 500;
  text-align: start;
}

.report-creation__satellite-image img {
  object-fit: contain;
  width: 400px;
  height: auto;
}


.report-creation__form-title {
  font-size: 1.2em;
}

.image-selection {
  display: flex;
  flex-direction: row;
}

.report-new-button {
  padding-bottom: 25px;
}

.report-new-button button {
  background: #BAC7A7;
  float: left;
  border-radius: 0.4em;
  border: none;
  font-weight: 700;
  color: #3A3A32;
  padding: 0.5em;
}

.report-new-button button:hover {
  background: #798777;
  cursor: pointer;
  color: #E5E4CC;
}

.report-creation__subtitle {
  margin-top: 9px;
}

.report-creation__region {
  padding-bottom: -20px;
}
.statistics-container {
  display: flex;
  justify-content: space-between;
  padding: 1em 1em;
}

.statistic {
  width: calc(25% - 10px);
  padding-top: 10px;
}

.statistic h4 {
  font-weight: bold;
  font-size: 20px;
}

.statistic p {
  font-size: 32px;
  font-weight: lighter;
}

.statistic-icon-container {
  display: inline-block;
  vertical-align: middle;
  margin-right: 0.5em;
}

.statistic-icon-container img {
  width: 1.5em;
  height: 1.5em;
}

.summary-header {
  display: flex;
  justify-content: space-between;
}

.summary-header h3 {
  text-align: left;
}

.summary-header h4 {
  text-align: right;
}

.summary__map-property-and-buttons-container {
  flex-direction: column;
  width: 100%;
  height: 100%;
}

.summary__map-and-property-container {
  display: flex;
  flex-direction: column;
}

.summary__map-box-container {
  width: 100%;
  height: 590px;
  flex-shrink: 0;
  padding: 10px;
}

.summary__property-labels-container {
  width: 88%;
  display: flex;
  justify-content: space-between;
  padding: 1em 1em;
  margin-left: 6%;
  text-align: left;
}

.summary__property-container {
  flex-direction: column;
  height: 500px;
  width: 100%;
  overflow-y: auto;
}

.summary__disturbances-table-container {
  display: flex;
  justify-content: center;
}

.summary__dndvi-event-table {
  width: 100%;
}

.summary__disturbances-table {
  width: 80%;
  max-width: 100%;
  border-radius: 10px;
  background: #E5F4E4;
  overflow-x: auto;
}

.summary__heading-title {
  background-color: #E5F4E4;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.summary__heading-title span {
  color: #4D4D4D;
  margin-left: auto;
}

.summary__disturbances-csv-download-button {
  z-index: 3;
  height: 40px;
  width: 40px;
  padding: 8px;
  border-radius: 5px;
  background: #FFF;
  cursor: pointer;
  border: none;
  margin: 10px;
  margin-left: auto;
  margin-right: 10px;
}

.summary__heading-rows {
  width: 100%;
  background-color: #E5F4E4;
  cursor: pointer;
}

.summary__disturbances-table th,
.summary__disturbances-table td {
  width: auto;
  max-width: none !important;
  text-align: center;
}

.summary__disturbances-table thead,
.summary__disturbances-table tbody {
  display: table;
  width: 100%;
  table-layout: fixed;
}

.sort-dropdown-container {
  position: 'relative';
  display: inline-flex;
  align-items: center;
}

.sort-dropdown-toggle {
  background-color: transparent;
  border: 2px solid black;
  border-radius: 15px;
  padding: 5px 10px;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.sort-text {
  font-size: 18px;
  margin-right: 5px;
}

.down-icon {
  width: 1em;
  height: 1em;
  margin-left: 5px;
}

.down-icon.detected-dates-table{
  margin-right: 5px;
}

.sort-dropdown-menu {
  position: absolute;
  left: 0;
  top: 100%;
  z-index: 100;
  background-color: rgb(255, 255, 255);
  width: 150px;
}

.sort-dropdown-menu ul {
  list-style-type: none;
  padding: 0;
}

.sort-dropdown-menu li {
  padding: 10px;
  cursor: pointer;
  border-bottom: 1px solid #ccc;
}

.sort-dropdown-menu li:last-child {
  border-bottom: none;
}
.satellite-image-form, .satellite-image-create-page{
  margin: auto;
  text-align: left;
}

.satellite-image-create-page-title {
  display: flex;
flex-direction: row;
justify-content: space-between;
align-items: flex-start;
padding: 16px 147px 16px;

position: static;
width: 1312px;
height: 100px;
left: 0px;
top: 0px;


/* Inside auto layout */

flex: none;
order: 0;
flex-grow: 0;
margin: 0px -39px;
}

.satellite-image-create-page-title h1 {
  font-style: normal;
  width: 16em;
  font-weight: 700;
  letter-spacing: -0.015em;
  color: #3D3D3D;
  font-size: 2em;
}

.metadata-headings, .satellite-image-form__details {
  font-style: normal;
  font-weight: 700;
  letter-spacing: -0.015em;
  font-size: 1.05em;
  line-height: 2.5em;
  color: #080808;
  margin-left: -56em;
}

.name-label{
  font-style: normal;
  font-weight: 700;
  letter-spacing: -0.015em;
  font-size: 1.05em;
  line-height: 2.5em;
  color: #3D3D3D;
  margin-left: -56em;
}

.satellite-image-form__details, .metadata-headings, .name-label {
  text-align: left;
  left: 0;
  margin-left: 110px;

}
/* 
.satellite-image-form__details input, .satellite-image-create-page input {
  margin-left: 10%;
  width: 30%;
} */

.create-satellite-button button {
  background: #E7E7E7;
  float: left;
  border-radius: 0.4em;
  border: none;
  font-weight: 700;
  color: #3A3A32;
  padding: 0.5em;
  margin-left: 21em;
  position: absolute;
  bottom: 18em;
  margin-top: 3em;
}

.create-satellite-button button:hover {
  background: #357960;
  cursor: pointer;
  color: #E5E4CC;
}

.satellite-image-form__details input {
  border: 1px solid #969696;
  position: absolute;
  left: 400px;
  width: 20%;
}

.name-input {
  border: 1px solid #969696;
  position: absolute;
  left: 400px;
  width: 20%;
}


.processing-text {
  position: absolute;
  color: #3D3D3D;
  left: 30px;
  font-weight: bold;
}



.request__collection {
  /* flexbox */
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: flex-start;
  align-content: flex-start;
  
  /* Box Model */
  width: 110%;
  height: 500px;
  overflow-y: scroll;
}

.request__collection::-webkit-scrollbar {
  width: 10px;
}

/* Track */
.request__collection::-webkit-scrollbar-track {
  background: transparent; 
}
 
/* Handle */
.request__collection::-webkit-scrollbar-thumb {
  background: #E9E9E9;
  border-radius: 5px;
}

/* Handle on hover */
.request__collection::-webkit-scrollbar-thumb:hover {
  background: #c4c4c4; 
}


.request-card__item {
  /* grow shrink base_size*/
  flex: 0 0 auto;
  text-decoration: none;
  width: 536px;
  height: 114px;
  left: 297px;
  top: 262px;

  margin-bottom: 2em;
  background: rgba(135, 135, 135, 0.12);
  border: 2px solid #000000;
  box-sizing: border-box;
  border-radius: 5px;
}

.request-card__reqAmount{
  position: relative;
  width: auto;
  height: 47px;
  /*left: 700px;*/
  margin-inline: 20px;

  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 25px;
  line-height: 50px;
  /* or 200% */

  letter-spacing: -0.015em;

  color: #323232;
}

.request-card__id{
  position: relative;
  width: 70px;
  height: 23px;
  left: 430px;

  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 30px;
  /* or 187% */

  letter-spacing: -0.015em;

  color: #757575;
}

.request-card__initial{


  margin-inline: 20px;

  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 40px;
  letter-spacing: -0.015em;

  color: #3A3A32;
}

.request-card__content{
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  align-content: center;
  width: auto;
  margin-block: 5px;
}

.initial_circle{
  width: 50px;
  height: 50px;
  border: 2px solid #3A3A32;
  border-radius: 50%;
  box-sizing:border-box;
}

.request-card__org{
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 22px;
  line-height: 30px;
  /* or 136% */

  letter-spacing: -0.015em;

  color: #3A3A32;
}

.request-card__owner{
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 30px;
  /* or 167% */

  letter-spacing: -0.015em;

  color: #3A3A32;
}

.satpage {
    width: 100%;
   
    height: 1000px;
    max-width: 1px;
    overflow-x: hidden;
    
}



.satellite-image-display__details {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 8px;
  
  position: relative;
  width: 416px;
  height: 665.45px;
  
  /* light surface */
  
  background: #FFFFFF;
  border: 1px solid #3A3A32;
  box-sizing: border-box;
  left: -207px;
  z-index: 10;
}
.satellite-image-display__details-frame {
  display: flex;
flex-direction: column;
align-items: center;
padding: 0px;

position: static;
left: 1.92%;
right: 1.92%;
top: 1.2%;
bottom: 1.2%;

/* backdrop-filter: blur(4px); */
/* Note: backdrop-filter has minimal browser support */


/* Inside auto layout */

flex: none;
order: 0;
flex-grow: 0;
margin: 0px 10px;
}

.satellite-image-display__details__header {
  display: flex;
flex-direction: row;
justify-content: center;
align-items: center;
padding: 8px;

position: static;
width: 400px;
height: 44px;
left: 0px;
top: 192px;


/* Inside auto layout */

flex: none;
order: 2;
align-self: stretch;
flex-grow: 0;
margin: 0px 0px;
}

.satellite-image-display__details__properties{
  font-size: 1.1em;
  font-style: normal;
  font-weight: normal;
  padding: 0.5rem;
  letter-spacing: -0.015em;
  line-height: 22px;
  color: #3A3A32;
}

.satellite-image-display {
  margin-top: -5.8em;
  width: 1em;
  margin-left: 2%;
}

.satellite-image-display-header {
  display: flex;
flex-direction: row;
justify-content: space-between;
align-items: center;
padding: 32px 16px 16px 8px;

position: absolute;
width: 1312px;
height: 80px;
left: 64px;
top: 64px;
}
.satellite-image-display-title {
  position: static;
left: 20.62%;
right: 20.62%;
top: 18.18%;
bottom: 18.18%;

font-family: Roboto;
font-style: normal;
font-weight: bold;
font-size: 24px;
line-height: 28px;
text-align: center;
letter-spacing: -0.015em;

color: #3A3A32;


/* Inside auto layout */

flex: none;
order: 0;
flex-grow: 0;
margin: 0px 10px;
}

.satellite-image-display h1 {
  font-style: normal;
  width: 10em;
  font-weight: bold;
  letter-spacing: -0.015em;
  text-align: left;
  color: #dd2e2e;
  
}

.section {
  font-family: Roboto;
font-style: normal;
font-weight: bold;
font-size: 20px;
line-height: 23px;
letter-spacing: -0.015em;

color: #4D4D4D;

}

.closebtn {
  all: unset;
  font-size: 2em;
  text-align: end;
  font-weight: 100;
  margin-right: 5%;
  margin-left: 40%;
  top: 0;
  margin-top: -2em;
  color: #3A3A32;
}

/* .openimage button{
  background: #BAC7A7;
  position: absolute;
  z-index: 10;
  margin-top: 160px;
  margin-right: 2%;
  right: 0;
  top: 0;
  border: none;
  font-weight: 700;
  color: #3A3A32;
  padding: 0.5em;
} */
.openimage button{
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px 17px;
  
  position: static;
  width: 204px;
  height: 36px;
  left: 8px;
  top: 8px;
  
  background: rgba(210, 210, 210, 0.19);
  /* dark surface */
  
  border: 2px solid #4D4D4D;
  box-sizing: border-box;
  border-radius: 15px;
  
  /* Inside auto layout */
  
  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 0px 0px;
}

.openimage button:hover {
  background: #357960;
  cursor: pointer;
  color: #E5E4CC;
}

.sat-image-display img {
  height: 500px;
  width: 500px;
} 


.image-bar{
  display: flex;
flex-direction: row;
justify-content: center;
align-items: center;
padding: 0px;

position: static;
width: 497px;
height: 52px;
left: 799px;
top: 22px;


/* Inside auto layout */

flex: none;
order: 1;
flex-grow: 0;
margin: 0px 0px;
}

.titlebox {
  display: flex;
flex-direction: row;
align-items: center;
padding: 8px;

position: static;
width: 253px;
height: 46px;
left: 8px;
top: 25px;


/* Inside auto layout */

flex: none;
order: 0;
flex-grow: 0;
margin: 0px 0px;
}

.titletext {
  position: static;
width: 240px;
height: 30px;
left: 8px;
top: 8px;

/* heading 3 */

font-family: Roboto;
font-style: normal;
font-weight: bold;
font-size: 26px;
line-height: 30px;
letter-spacing: -0.005em;

/* dark surface */

color: #4D4D4D;


/* Inside auto layout */

flex: none;
order: 0;
flex-grow: 0;
margin: 0px 8px;
}

.regionbox {
  display: flex;

justify-content: center;
align-items: flex-start;
padding: 0px;

position: static;
width: 259px;
height: 37px;
left: 0px;
top: 7.5px;


/* Inside auto layout */

flex: none;
order: 0;
flex-grow: 0;
margin: 0px 18px;

}

.imagedetailsbox{
  display: flex;
flex-direction: column;
align-items: flex-start;
padding: 8px;

position: static;
width: 220px;
height: 52px;
left: 277px;
top: 0px;


/* Inside auto layout */

flex: none;
order: 1;
flex-grow: 0;
margin: 0px 18px;
}


 .imageheadingbox {
   /* Auto layout */

   display: flex;
   flex-direction: row;
   align-items: flex-start;
   padding: 8px;
   
   position: static;
   width: 72px;
   height: 37px;
   left: 0px;
   top: 0px;
   
   
   /* Inside auto layout */
   
   flex: none;
   order: 0;
   flex-grow: 0;
   margin: 0px 0px;
 }

 .imageheadingtext {
  position: static;
  width: 56px;
  height: 21px;
  left: 8px;
  top: 8px;
  
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 21px;
  /* identical to box height */
  
  letter-spacing: 0.0015em;
  
  color: #3A3A32;
  
  
  /* Inside auto layout */
  
  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 0px 10px;
  
 }

 .imagenameanddate{
  display: flex;
flex-direction: row;
align-items: flex-start;
padding: 8px;

position: static;
width: 187px;
height: 37px;
left: 72px;
top: 0px;


/* Inside auto layout */

flex: none;
order: 1;
flex-grow: 0;
margin: 0px 0px;}

 .imagenameanddate-text{
  position: static;
  width: 215px;
  height: 21px;
  left: 8px;
  top: 8px;
  
  /* subtitle */
  
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 21px;
  /* identical to box height */
  
  text-align: center;
  letter-spacing: 0.0015em;
  
  color: #3A3A32;
  
  
  /* Inside auto layout */
  
  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 0px 0px;
  
 }

 

 .mapbox {
  display: flex;
flex-direction: column;
align-items: center;
padding: 8px;

position: absolute;
height: 713px;
left: 64px;
right: 64px;
top: 144px;

border: 1px solid #4D4D4D;
box-sizing: border-box;
border-radius: 4px;

 }

 .cancel-box {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;
  
  position: static;
  width: 76px;
  height: 39px;
  left: 162px;
  top: 594.45px;
  
  
  /* Inside auto layout */
  
  flex: none;
  order: 11;
  flex-grow: 0;
  margin: 0px 0px;
  
 }

 .canceltext {
  position: static;
  left: 10.53%;
  right: 10.53%;
  top: 20.51%;
  bottom: 20.51%;
  
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 23px;
  text-align: center;
  letter-spacing: -0.015em;
  
  color: #3A3A32;
  
  
  /* Inside auto layout */
  
  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 20px -10px;
  border-color: transparent;
    background-color: transparent;
    cursor: pointer;
 }

 .shapefileinfo-box {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 8px;
  
  position: static;
  width: 256px;
  height: 85.45px;
  left: 72px;
  top: 509px;
  
  
  /* Inside auto layout */
  
  flex: none;
  order: 10;
  flex-grow: 0;
  margin: 0px 0px;
 }

 .shapefileinfobutton {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0px 64px;
  
  position: static;
  Height:69.45px;
  width: 240px;
  left: calc(50% - 240px/2);
  top: 9.36%;
  bottom: 9.36%;
  
  /* primary */
  
  background: #357960;
  border-radius: 10px;
  border-color: transparent;
  
  /* Inside auto layout */
  
  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 10px 0px;


  
 }

 .shapefileinfotextbox {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 8px;
  
  position: static;
  width: 185px;
  height: 39px;
  left: 27.5px;
  top: 15.23px;
  
  
  /* Inside auto layout */
  
  flex: none;
  order: 0;
  flex-grow: 0;
  margin: -61px 28px;
 }

 .shapefileinfotext {
  position: static;
  left: 4.32%;
  right: 4.32%;
  top: 20.51%;
  bottom: 20.51%;
  
  /* button */
  
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 23px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: -0.015em;
  
  /* light surface */
  
  color: #FFFFFF;
  
  
  /* Inside auto layout */
  
  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 0px 0px;
 }
.details-box {
 display: flex;
flex-direction: row;
align-items: flex-start;
padding: 8px;

position: static;
width: 400px;
height: 39px;
left: 0px;
top: 470px;


/* Inside auto layout */

flex: none;
order: 9;
align-self: stretch;
flex-grow: 0;
margin: 0px 0px;
}

.details-picture-box {
  display: flex;
flex-direction: row;
align-items: flex-start;
padding: 8px;

position: static;
width: 176px;
height: 176px;
left: 112px;
top: 16px;


/* Inside auto layout */

flex: none;
order: 1;
flex-grow: 0;
margin: 0px 0px;
}

.observation-toolbox-frame {
  display: flex;
flex-direction: row;
align-items: center;
padding: 16px;

position: absolute;
width: 280px;
height: 80px;
left: -35px;
top: 430px;

background: #E5F4E4;
border-radius: 8px;
transform: rotate(-90deg);
z-index:10;
}

.observation-toolbox-button {
  position: absolute;
width: 280px;
height: 80px;


/* button */

font-family: Roboto;
font-style: normal;
font-weight: 500;
font-size: 20px;
line-height: 23px;
text-align: center;
letter-spacing: -0.015em;

/* primary */

color: #357960;


/* Inside auto layout */

flex: none;
order: 0;
flex-grow: 1;
margin: 0px -20px;
background: transparent;
border-color: transparent;
cursor:pointer;
}

.remote-sensing-icon {
  left: 4.55%;
right: 4.55%;
top: 4.55%;
bottom: 4.55%;


/* Inside auto layout */

flex: none;
order: 0;
flex-grow: 0;
margin: 27px 18px;
Width :120px;
Height: 120px;
}

.magnifying-glass-icon {
  position: static;
left: 9.8%;
right: 78.43%;
top: 16.67%;
bottom: 16.67%;


/* Inside auto layout */

flex: none;
order: 0;
flex-grow: 0;
margin: 0px 0px;
}
.toolbox {
display: flex;
flex-direction: row;
align-items: flex-start;
padding: 8px;

position: absolute;
width: 416px;
height: 731px;

/* light surface */

background: #FFFFFF;
border: 1px solid #3A3A32;
box-sizing: border-box;
z-index: 20;
margin-left: 11px;
}

.toolbox-frame {
  display: flex;
flex-direction: column;
align-items: center;
padding: 0px;

position: static;
left: 1.92%;
right: 1.92%;
top: 1.09%;
bottom: 1.09%;





/* Inside auto layout */

flex: none;
order: 0;
flex-grow: 0;
margin: 0px 10px;
}

.toolbox-cancel {
  display: flex;
flex-direction: row;
align-items: flex-start;
padding: 8px;

position: static;
width: 66px;
height: 39px;
left: 167px;
top: 660px;


/* Inside auto layout */

flex: none;
order: 4;
flex-grow: 0;
margin: 0px 0px;
}

.toolbox-list{
  display: flex;
flex-direction: column;
align-items: flex-start;
padding: 4px;

position: static;
width: 400px;
height: 424px;
left: 0px;
top: 236px;


/* Inside auto layout */

flex: none;
order: 3;
align-self: stretch;
flex-grow: 0;
margin: 0px 0px;
}

.toolbox-options {
  display: flex;
flex-direction: row;
align-items: center;
padding: 16px;

position: static;
width: 392px;
height: 80px;
left: 4px;
top: 340px;

background: #E5F4E4;
border-radius: 4px;

/* Inside auto layout */

flex: none;
order: 4;
align-self: stretch;
flex-grow: 0;
margin: 4px -10px;
font-family: Roboto;
font-style: normal;
font-weight: 500;
font-size: 20px;
line-height: 23px;
letter-spacing: -0.015em;
border-color: transparent;
cursor: pointer;

/* primary */

color: #357960;
}

.toolbox-options:hover {
  border-color: #357960;
  color: #357960;
   /* font-size: 22px;  */
  border-radius: 7px;
  
  font-weight: 700;
  border-width: 5px;


}

.toolbox-header{
  display: flex;
flex-direction: row;
justify-content: center;
align-items: center;
padding: 8px;

position: static;
width: 400px;
height: 44px;
left: 0px;
top: 192px;


/* Inside auto layout */

flex: none;
order: 2;
align-self: stretch;
flex-grow: 0;
margin: 0px 0px;

font-family: Roboto;
font-style: normal;
font-weight: bold;
font-size: 24px;
line-height: 28px;
text-align: center;
letter-spacing: -0.015em;

color: #3A3A32;
}

.toolbox-image{
  display: flex;
flex-direction: row;
align-items: flex-start;
padding: 8px;

position: static;
width: 176px;
height: 176px;
left: 112px;
top: 16px;


/* Inside auto layout */

flex: none;
order: 1;
flex-grow: 0;
margin: 0px 0px;
}
.back-btn {
  all: unset;
  cursor: pointer;
  align-self: flex-start;
  margin-left: 1em;
  margin-top: 1em;
}

.tab-body-wrapper {
  display: flex;
  flex-direction: row;
}

.tab-body {
  margin-left: 2em !important;
}

.tab-body > h1 {
  font-size: 25px;
  text-align: left;
  color: #3D3D3D;
  margin-bottom: 1em;
}

.about-tab-bar {
  border-radius: 20px;
  background: #E7E7E7;
  width: 37em;
  height: 2.4em;
}

.tab-heading {
  display: flex;
  flex-flow: row nowrap;
  align-items: left;
  align-content: center;
  margin: 0;
}

.tab-heading > h1 {
  font-size: 30px;
  line-height: 100%;
  letter-spacing: -0.015em;
  text-decoration-line: underline;
  margin-top: 1em;
  margin-left: 1em;
}

.tab-info-body {
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 23px;
  letter-spacing: -0.015em;
  color: #3A3A32;
  text-align: left;
  height: 100%;
  margin-left: 1.3em;
}

.initial-circle {
  border: 2px solid #3A3A32;
  box-sizing: border-box;
  width: 55px;
  height: 55px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  margin-left: 2em;
  margin-top: 1.2em;
}

.initial-circle > p {
  margin: 0;
  font-weight: 500;
  font-size: 25px;
  line-height: 29px;
}

.button-box {
  display: flex;
  flex-direction: row;
}

.accept-btn {
  width: 12em;
  height: 2em;
  background: #BAC7A7;
  border-radius: 10px;
  border: none;
  font-weight: bold;
  font-size: 20px;
  line-height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  margin-top: 2em;
  color: #3A3A32;
}

.decline-btn {
  width: 12em;
  height: 2em;
  background: #E3E1E1;
  border-radius: 10px;
  border: none;
  font-weight: bold;
  font-size: 20px;
  line-height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  margin-top: 2em;
  margin-left: 1.8em;
  color: #3A3A32;
}
.hamburger-btn {
  position: absolute;
  /* should be put on top of dropdown menu */
  z-index: 3; 
  top: 11px;
  left: 11px;
  padding: 10px;
  height: 44px;
  width: 44px;
  border-radius: 10px;
  background: #FFF;
  cursor: pointer;
}

.sidebar>.close-btn {
  padding: 20px;
  cursor: pointer;
}

.sidebar>.bolded-text {
  padding: 0px 0px 20px 0px;
  text-align: left;
  color: #3A3A32;
  width: 296px;
  height: fit-content;
  /* Heading 3 */
  font-family: Roboto;
  font-size: 19px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.095px;
}

.sidebar>.normal-text {
  text-align: left;
  color: #3A3A32;
  /* Paragraph <p> */
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 140%;
  letter-spacing: 0.08px;
}

.sidebar {
  z-index: 1000;
  position: absolute;
  padding: 20px;
  width: 394px;
  height: 100%;
  flex-shrink: 0;
  border-radius: 0px 10px 10px 0px;
  background: #FFF;
  box-shadow: 4px 4px 4px 0px rgba(0, 0, 0, 0.25);
}

.slider-container {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  margin-bottom: 20px;
}

.slider-labels {
  display: flex;
  justify-content: space-between;
}

.slider-container input[type="range"] {
  width: 100%;
}

input[type="range"] {
  -webkit-appearance: none; 
  appearance: none;
  cursor: default;
  color: #4D4D4D;;
  padding: initial;
  border: initial;
  margin: 2px;
}

input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  background: #4D4D4D;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 2px solid #FFFFFF; 
  margin-top: -8px;
}

input[type="range"]::-webkit-slider-runnable-track {
  background: #4D4D4D;
  height: 8px; 
  border-radius: 4px; 
}
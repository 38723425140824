.monitor-popup-content {
  width: 75.375em;
  height: 30.75em;
  background: #CFE1CE;
  opacity: 1;
  margin: auto;
  -webkit-animation: popup 0.3s;
}

.monitor-popup-overlay {
  background: rgba(0, 0, 0, 0.5);
}
[data-popup='tooltip'].popup-overlay {
  background: transparent;
}

.popup-cards {
  margin-top: 1.5em;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  font-size: 20px;
}

.exit-btn {
  background: none;
  border: none;
  float: left;
  margin-top: 6em;
  margin-left: 1em;
  cursor: pointer;
}

.exit-btn:hover {
  transform: scale(1.05);
}

.popup-body {
  padding-top: 2em;
}

.popup-body > h1 {
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 30px;
  text-align: center;
}

.popup-btn {
  filter: drop-shadow(8px 8px 4px rgba(0, 0, 0, 0.25));
  border-radius: 10px;
  background-color: #BAC7A7;
  width: 11.8em;
  height: 2.05em;
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  text-align: center;
  letter-spacing: -0.015em;
  color: #3A3A32;
  border: none;
  cursor: pointer;
  margin-top: 1em;
}

.popup-btn:hover {
  transform: scale(1.05);
  border: none;
}

@keyframes popup {
  0% {
    transform: translateX(100vw);
  }
  100% {
    transform: translateX(0);
  }
}

.card-descriptor {
  font-weight: bold;
  font-size: 13px;
  line-height: 15px;
  text-align: center;
  color: #434343;
  height: 1em;
}
.project-new__body {
  display: flex;
  flex-direction: row;
  margin-bottom: 3em; 
}

.project-new__back-col {
  width: 5em;
}

.project-new__form-col {
  margin-left: 1.5em;
  margin-right: 1.5em;
  width: 100%;
  position: relative;
}

.project-new__back-btn {
  margin-top: 1.5em !important;
  margin-left: 1em !important;
  justify-self: center;
  align-self: center;
  visibility: hidden;
}

.project-new__form__body {
  font-style: normal;
  text-align: start;
  color: #3A3A32;
  letter-spacing: -0.015em;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.project-new__input-block {
  height: auto;
  margin-bottom: 2%;
}

.project-new__form textarea,
.project-new__form select {
  resize: none;
  border-color: #357960;
  width: auto;
  min-width: 25%;
  /* light surface */
  background: #FFFFFF;
  /* primary */
  border: 1px solid #357960;
  border-radius: 10px;

}

.project-new__form textarea {
  /* height: 20%; */
  /* padding-left: 10px;
  padding-top: 10px;
  padding-right: 10px;
  padding-bottom: 10px; */
}

.project-new__form__body input {
  width: auto;
  min-width: 25%;
  border-color: #357960;
  padding-top: 15px;
  padding-bottom: 15px;
  padding-right: 10px;
  padding-left: 10px;
  border-radius: 5px;
  border-width: 1px;
  border-style: solid;
}

.custom-characteristics-container {
  width: 25%;
  display: inline-block;
}

.project-new__form label {
  font-family: 'Roboto Medium', sans-serif;
  font-size: 18px;
  color: #000;
  font-weight: 400;
  display: inline-block;
  vertical-align: top;
  margin-bottom: 5px;
}

.project-buttons {
  margin-top: 2%;
}

.upload-file-button label {
  display: inline-box;
  min-height: 10%;
  min-width: 15%;
  padding-left: 1%;
  padding-right: 1%;
  background-color: #E7E7E7;
  box-sizing: border-box;
  border: 2px solid #4D4D4D;
  border-radius: 15px;
  text-align: center;
  vertical-align: middle;
  line-height: 35px;
}

.project-new-button {
  margin-top: 2%;
}

.project-next-button {
  text-align: center;
  margin-left: 5%;
  margin-right: 5%;
  position: inherit;
  background: #357960;
  min-width: 25%;
  border-radius: 0.4em;
  border: none;
  font-weight: 500;
  font-weight: 500;
  color: #ffffff;
  padding: 0.5em;
}

.project-next-button:hover {
  background: #798777;
  cursor: pointer;
  color: #E5E4CC;
}

.project-new-button input,
.project-new-button button {
  background: #357960;
  width: auto;
  min-width: 20%;
  border-radius: 0.4em;
  border: none;
  font-weight: 700;
  color: #ffffff;
  padding: 0.5em;
  margin-right: 2%;
  padding-left: 2em;
  padding-right: 2em;
}

.project-new-button input:hover,
.project-new-button button:hover {
  background: #798777;
  cursor: pointer;
  color: #E5E4CC;
}

.project-new-button input:disabled,
.project-new-button button:disabled {
  background: #E7E7E7;
  color: #FFFFFF;
}

.project-new-title {
  width: 1em;
}

.project-new-title h1 {
  padding: 20px 0px;
  font-style: normal;
  width: 10em;
  font-weight: 700;
  letter-spacing: -0.015em;
  color: #3D3D3D;
  text-align: left;
  font-size: 2em;
}

.project-new__warning-text {
  color: #DA4167;
}

.mapbox-selection-div {
  width: 80%;
  height: 30rem;
  margin: auto;
}

.mapbox-selection-div textarea {

  resize: none;
  display: inline-block;
  border-color: #357960;
  width: 20%;
  box-sizing: border-box;
  /* light surface */
  background: #FFFFFF;
  /* primary */
  border: 1px solid #357960;
  border-radius: 10px;
  margin: 1%;
}

.project-instructions {
  margin-left: auto; 
  margin-right: auto; 
  width: 80%;
  margin-top: 2%;
  padding: 0.5%;
  border: 1px solid #357960;
}
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700&display=swap');

body {
  text-align: center;
  font-family: 'Roboto', sans-serif;
  
}

/* Colours as defined from Figma: https://www.figma.com/file/1Shh83liRkbSQaRSjwH3tW/DelayedHayScreens_Current?node-id=21%3A513 */
/* Updated Dec 1, 2021 */
:root {
  --primary: #357960;
  --light-primary: #D8EDD4;
  --light-surface: #FFFFFF;
  --light-subtle-surface: #E7E7E7;
  --dark-subtle-surface: #B5B5B5;
  --dark-surface: #4D4D4D;
  --highlight: #DA4167;
}
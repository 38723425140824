.report-display-page {

}

.lulc-report-details {
    display: flex;
    flex-direction: row;
}

.report2__details {
    margin-left: 400px;
    margin-top: -8px;
}

.satellite-image img, .landcover-image img {
    width: 300px;
    height: 300px;
}

.change-image img {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 500px;
    height: 450px;
}

.report__satellite__details__image {
    line-height: 10px;
}

.open-change-legend, .open-second-legend {
    position: absolute;
    right: 0;
    margin-right: 10%;
}

.open-first-legend {
    position: absolute;
    left: 0;
    margin-left: 40%;
}

.open-change-legend button, .open-first-legend button, .open-second-legend button {
    all: unset;
    padding: 0.3em 1.2em 0.3em 1.2em;
    right: 0;
    background: #C4C4C4;
    font-style: normal;
    font-weight: 500;
}

.open-change-legend button:hover, .open-first-legend button:hover, .open-second-legend button:hover {
    cursor: pointer;
}

.acd__lulc1__details {
    position: absolute; 
    left: 0;
    margin-left: 30%;
    border: 0.1rem solid #7C9473;
    padding: 1em;
    margin-top: -20em;
}

.acd__lulc2__details {
    position: absolute; 
    right: 0;
    margin-right: 13%;
    border: 0.1rem solid #7C9473;
    padding: 1em;
    margin-top: -20em;
}

.acd__change__details {
    position: absolute; 
    right: 0;
    margin-right: 10%;
    border: 0.1rem solid #7C9473;
    padding: 1em;
    margin-top: -30em;
}


.close-btn {
    all: unset;
    font-size: 1.8em;
    text-align: end;
    font-weight: 100;
    right: 0;
    margin-right: 10px;
    top: 2px;
    position: absolute;
    color: #3A3A32;
}
.report-new-button{
    margin-top: 200px;
}
.detail-display-card {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: auto;
  margin-block: 5px;
  padding: 1.5rem 4rem;
  border-radius: 10px;
  background: white;
  height: 460px;
  border: solid 3px var(--primary);
  justify-content: space-between;
}

.detail-display-card h4 {
  margin: 10px;
}

.detail-display-card p {
  margin: 10px;
}

.observations-imgs {
  display: flex;
  width: 100%;
  overflow-x: scroll;
}
.observation-img {
  object-fit: cover;
  margin: 15px;
  width: 400px;
  height: 400px;
}

.observation-btns {
  display: flex;
  align-self: center;
}
.observation-btns button {
  margin: 10px;
  height: 4rem;
  width: 21.625rem;
}

.display-card-back-btn {
  position: absolute;
  top: 2rem;
  left: 20px;
  max-width: 2rem;
  height: auto;

  cursor: pointer;
}

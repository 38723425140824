.mapSection{
  margin-top: 5rem;
}

.sidebarStyle {
  display: inline-block;
  position: absolute;
  top: 0;
  left: 0;
  margin: 12px;
  background-color: blue;
  color: #ffffff;
  z-index: 1 !important;
  padding: 6px;
  font-weight: bold;
}

.mapContainer {

  position: relative;
  width: 100%;
  height: 100%;
}

.mapSection{
  position: relative;
  display: flex;
  justify-content: center;
  width: 500px;
  height: 500px;

  margin:auto
}
.acd-creation {
    width: 80%;
    margin-top: -5.8em;
    margin-left: 2%;
    color: #3A3A32;
    text-align: start;
}

.acd-creation__title h1 {
    font-style: normal;
    width: 30em;
    font-weight: bold;
    letter-spacing: -0.015em;
    text-align: left;
    color: #3D3D3D;
}

.acd-creation p {
    letter-spacing: -0.015em;
    line-height: 1em;
    color: #3A3A32;
    font-style: normal;
    font-weight: 500;
    text-align: start;
}
 
.acd-creation__satellite-image img {
    object-fit: contain;
    width: 300px;
    height: auto;
}
  
.acd-type-buttons {
    display: flex;
    flex-direction: row;
}
  
.acd-type-buttons button {
    border: 1px solid #707070;
    margin-left: 10px;
    box-sizing: border-box;
    border-radius: 15px;
    padding: 0.4em 1.1em 0.4em 1.1em;
}

.acd-type-buttons button:hover {
    cursor: pointer;
}

.report-new-button {
    margin-bottom: 20px;
}
  
.report-new-button button {
    background: #E7E7E7;
    float: left;
    border-radius: 0.4em;
    border: none;
    font-weight: 700;
    color: #3A3A32;
    padding: 0.5em;
}
  
.report-new-button button:hover {
    background: #357960;
    cursor: pointer;
    color: #E5E4CC;
}

.image-selection {
    display: flex;
    flex-direction: row;
}

.select-report-dropdown {
    position: absolute;
    margin-left: 100px;
}

.image2 {
    margin-left: 20%;
}

.image2__selection, .image1__selection {
    display: flex;
    flex-direction: row;
}

.acd-creation input {
    margin-left: -170px;
}
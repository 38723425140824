.dropdown {
    background: rgba(238, 238, 238, 0.829);
    border: none;
    border-radius: 0em 0.3em 0.3em 0;
    padding: 0.3em;
    padding-left: 0.5em;
    width: 25%;
    z-index: 10;
    position: absolute;
    left: -14px;
    top: 20px;
}


.report-gallery__title{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    padding: 32px 16px 16px;
    
    position: absolute;
    width: 1312px;
    height: 100px;
    left: 63px;
    top: 64px;
    margin-left: -28px;
}
  
.report-gallery__title h1 {
    font-style: normal;
    width: 26em;
    font-weight: bold;
    letter-spacing: -0.015em;
    color: #3D3D3D;
    font-size: 2em;
}
  
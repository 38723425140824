/* Payment Dashboard */

.payment-db-title {
    width: 265px;
    height: 50px;
    margin-left: 1.1em;
    margin-top: -4rem;

    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
    font-size: 30px;
    line-height: 50px;
    /* identical to box height, or 167% */

    letter-spacing: -0.015em;

    color: #3D3D3D;
}

.payment-db-body {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    height: 100%;
    margin-bottom: 4em;
    margin-top: 1em;
}

.selection-gallery {
  /* for later use */
}

.page-heading {
display: flex;
flex-direction: row;
/* justify-content: space-between; */
align-items: flex-start;
padding: 32px 16px 16px;

position: relative;
width: 90%;
height: 80px;
left: 5%;
top: 0px;

/* Inside auto layout */

flex: none;
order: 0;
align-self: stretch;
flex-grow: 0;
margin: 0px 0px;
}
.selection-gallery__title { 
  /* margin-left: 5rem; */
  text-align: left;
}

.sort-icon{
  position: relative;
  /* margin-right: 200px;
  margin-left: 240px;
  top: 128px; */
  /* bottom: 10px; */
  top: auto;
  bottom: -10%;
  width: 32px;
  height: 25px;

/* dark surface */

}
  
.selection-gallery__title h1 {
    font-style: normal;
    width: 10em;
    font-weight: bold;
    letter-spacing: -0.015em;
    color: #3D3D3D;
}
.image-gallery__title-import{
  display: flex;
flex-direction: row;
align-items: center;
padding: 0px 17px;

position: static;
width: 127px;
height: 36px;
left: 8px;
top: 8px;

background: rgba(210, 210, 210, 0.19);
/* dark surface */

border: 2px solid #4D4D4D;
box-sizing: border-box;
border-radius: 15px;

/* Inside auto layout */

flex: none;
order: 0;
flex-grow: 0;
margin: 0px 0px;
cursor: pointer;
justify-content: space-around;
  align-items: center;
}

.create-new-project{
  /* Auto layout */

display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
padding: 0px 10px;
/* margin-right: 0px; */
margin-left: -4px;

font-family: 'Roboto';
font-style: normal;
font-weight: 500;
font-size: 20px;
line-height: 23px;
text-align: center;
letter-spacing: -0.015em;

/* light surface */

color: #FFFFFF;


/* Inside auto layout */

flex: none;
order: 0;
flex-grow: 0;

width: 165px;
height: 80px;
align-items: center;
position: static;


/* primary */

background: #357960;
border-radius: 10px;

/* Inside auto layout */

flex: none;
order: 2;
flex-grow: 0;
}

.image-gallery__title-import:hover{
    background-color: #357960;
    color: white;
}

.create-new-project-main-page:hover {
background: #9ebbb0;
cursor: pointer;
}


.create-new-project-main-page{
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px 10px;
  /* margin-right: 0px; */
  margin-left: 9%;
  border: 0px;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 23px;
  text-align: center;
  letter-spacing: -0.015em;
  
  /* light surface */
  
  color: #FFFFFF;
  
  
  /* Inside auto layout */
  
  flex: none;
  order: 0;
  flex-grow: 0;
  
  width: 240px;
  height: 40px;
  align-items: center;
  position: relative;
  margin-top: -3%;
  
  
  /* primary */
  
  background: #357960;
  border-radius: 10px;
  
  /* Inside auto layout */
  
  flex: none;
  order: 2;
  flex-grow: 0;
}

.new-proj-link {
  text-decoration: none;
}
.automatic-detection-page__content-wrapper {
  position: relative;
  width: 100%;
  height: 70vh;
  display: grid;
  grid-template-columns: 350px 3fr;
  grid-column-gap: 2rem;
}

.automatic-detection-page__info-wrapper {
  text-align: left;
  z-index: 2;
  height: 100%;
}

.automatic-detection-page__selections-container {
  display: flex;
  text-align: left;
  flex-wrap: wrap;
  width: 70vw;
  justify-content: space-between;
  align-items: center;
  margin-left: 100px;
}

.automatic-detection-page__select {
  flex: 1;
  box-sizing: border-box;
  text-align: left;
}

.automatic-detection-page__select-label {
  padding-bottom: 10px;
}

.automatic-detection-page__select-subcontainer {
  width: 200px;
  min-width: min-content;
  box-sizing: border-box;
  margin-left: auto;
  margin-right: auto;
  /* background-color: #99DEE7; */
}

.automatic-detection-page__map-container {
  position: relative;
}

.automatic-detection-page__legend-wrapper {
  height: 100%;
  display: flex;
  flex-direction: column;
  flex: 1;
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.automatic-detection-page__legend-item {
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: space-evenly;
}

.automatic-detection-page__legend-item--first-line {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.automatic-detection-page__legend-item--first-line> :not(:last-child) {
  /* Add spacing to the right side of all elements except the last one, the info icon */
  margin-right: 10px;
}

.automatic-detection-page__legend-item--second-line {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.automatic-detection-page__legend-icon {
  width: 24px;
  height: 24px;
  border: 2px solid #000;
  margin-right: 1rem;
}

.high-vegetation-loss {
  background: #CB1919;
}

.med-vegetation-loss {
  background: #EE8080;
}

.low-vegetation-loss {
  background: #FFCECE;
}

.low-vegetation-gain {
  background: #99DEE7;
}

.med-vegetation-gain {
  background: #4C8CB0;
}

.high-vegetation-gain {
  background: #0040E5;
}

.automatic-detection-page__legend-divider {
  border-color: #4D4D4D;
  border-width: 1px;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  margin-left: 0;
  margin-right: 0;
  width: 100%;

}

.dndvi-image-container {
  position: relative;
  width: 100%;
  height: 100%;
  max-width: 70vw;
  max-height: 70vh;
  background-color: #f8f8f8;
  overflow: hidden;
}

.leaflet-container {
  width: 100%;
  height: 100%;
  z-index: 0;
}

.leaflet-control-layers {
  text-align: left;
}

.loading-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
}

.popup {
  position: absolute;
  z-index: 3;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
  display: flex;
  align-items: center;
  justify-content: center;

  border-radius: 10px;
  border: 0.5px solid var(--dark-surface, #4D4D4D);
  background: var(--light-surface, #FFF);

  color: #4D4D4D;
  /* Paragraph <p> */
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 140%;
  /* 22.4px */
  letter-spacing: 0.08px;

}

.info-icon {
  text-align: left;
  z-index: 2;
  height: 100%;
  margin-left: 10px;
}

.dndvi-event-table {
  position: absolute;
  right: 0;
  bottom: 15px;
}

.dndvi-event-no-table {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 300px;
  height: 30px;
  color: #4D4D4D;
  border-radius: 10px;
  background-color: #E5F4E4;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}

.disturbances-table {
  border-radius: 10px;
  background: #FFF;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  margin-right: 10px;
  margin-bottom: 5px;
}

.heading-rows {
  background-color: #E5F4E4;
  position: relative;
}

.disturbances-table-title {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  margin-top: auto;
  margin-bottom: auto;
}

.disturbances-table-title.closed {
  border-radius: 10px; 
}

.heading-rows span {
  color: #4D4D4D;
  margin-left: 75px;
  margin-right: 20px;
}

tr {
  border-top: 0;
  border-bottom: 0;
  border-radius: 10px;
}

tbody tr:nth-child(even) {
  background-color: #E5F4E4;
}

tbody tr:nth-child(odd) {
  background-color: #FFF;
}

.event-rows {
  cursor: pointer;
}

td {
  border-right: none;
  border-left: none;
  color: var(--dark-surface, #4D4D4D);
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 140%;
  letter-spacing: 0.08px;
}

td:last-child {
  border-bottom-right-radius: 10px;
}

td:first-child {
  border-bottom-left-radius: 10px;
}

th {
  max-width: 175px;
  border-left: none;
  border-right: none;
  color: var(--dark-surface, #4D4D4D);
  text-align: center;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.initial-popup {
  position: absolute;
  top: 10px;
  left: 50%;
  transform: translateX(-50%);
  background-color: #fff;
  padding: 10px;
  border: 0.5px solid #4D4D4D;
  border-radius: 8px;
  display: flex;
  z-index: 9;
}


.initial-popup p {
  margin-right: 15px; 
}


.initial-popup button {
  background: url("../images/close-popup-button.png") no-repeat;
  background-size: contain; 
  border: none;
  width: 10px; 
  height: 10px;
  margin-top: 4px;
  cursor: pointer;
}

.loading-container {
  background: #FFF;
  width: 100%;
  padding: 10px;
  box-sizing: border-box;
  border-radius: 5px;
  border-style: solid;
  border-color: #cfcfcf;
  border-width: 1px;
}

.automatic-detection-page__download-button {
  z-index: 1;
  height: 40px;
  width: 40px;
  padding: 8px;
  border-radius: 5px;
  background: #FFF;
  cursor: pointer;
  position: absolute;
  top: 10px;
  right: 10px;
  border: none;
}

.disturbances-csv-download-button {
  z-index: 3;
  height: 40px;
  width: 40px;
  padding: 8px;
  border-radius: 5px;
  background: #FFF;
  cursor: pointer;
  margin: 10px;
  border: none;
  right: 0;
}

.disturbances-table-toggle-button {
  background-color: transparent;
  z-index: 3;
  height: 40px;
  width: 40px;
  padding: 8px;
  border-radius: 5px;
  cursor: pointer;
  margin: 10px;
  border: none;
  left: 0;
  position: absolute;
}

.disturbances-table-icon {
  height: 20px;
  width: 20px;
  margin: 5px;
}
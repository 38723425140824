.forest-form {
  text-align: left;
  margin-top: -40px;
}

#mowing-details {
  height: 555px;
}

.satellite-image-create-page-title {
  height: 75px;
}

.metadata-headings-report-name-input {
  position: relative;
  width: 348px;
  height: 32px;
  margin-left: 110px;
  background: #ffffff;
  border-radius: 10px;
  border-width: 1px;
}

.name-input {
  position: relative;
  width: 348px !important;
  height: 32px;
  margin-left: -290px;
  border-radius: 10px;
  margin-top: 5px;
}

.fcw-form-label {
  margin-left: 110px;
  color: var(--dark-surface, #3A3A3A);
  font-family: Roboto;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.027px;
}

.label-text {
  margin-top: 5px;
  margin-bottom: 5px;
}

.static-input-box {
  position: relative;
  width: 348px !important;
  height: 32px;
  margin-left: -290px;
  background: #E7E7E7;
  border-radius: 10px;
  margin-top: 5px;
}

.selectable-input-box {
  position: relative;
  width: 100px;
  height: 32px;
  margin-left: -290px;
  background: #ffffff;
  border-radius: 10px;
}

.options-dropdown {
  position: relative;
  width: 348px;
  height: 32px;
  background: #ffffff;
  border-radius: 10px;
}

.report-range-explanation {
  white-space: pre-line;
  color: var(--dark-subtle-surface, #B5B5B5);
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 140%;
  /* 22.4px */
  letter-spacing: 0.08px;
}

.empty-name-warning {
  padding-top: 24px;
  margin-left: 340px;
  width: 230px;
}

.button-for-mowing button{

  background: #357960;
  float: left;
  border-radius: 10px;
  border: none;
  font-weight: 700;
  width: 200px;
  color: #FFFFFF;
  padding: 0.5em;
  margin-top: 1em; 
  margin-left: 7em;
  /* position: absolute; */
  bottom: 20em;
}

.button-for-mowing button:hover{
  opacity: 0.4; 
}
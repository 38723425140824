.carbon-report_mapbox-container {
  position: absolute;
  height: 100%;
  top: 20%;
  width: 100%;
}

.closeIcon {
  max-height: 23px;
  width: auto;
  margin: 10px;
  cursor: pointer;
}

.carbon-report_mapbox {
  position: absolute;
  justify-content: center;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
}

.showGEDI {
  z-index: 200;
  right: 20px;
  top: 27rem;
  position: absolute;
  background: rgba(238, 238, 238, 1);
  border: none;
  border-radius: 0.5em 0 0 0.5em;
  padding: 0.4em;
  padding-left: 0.5em;
  padding-right: 0.5em;
}

.legend-div {
  left: 1%;
  top: 15%;
  width: 180px;
  height: 45%;
  position: absolute;
  z-index: 200;
}

.legendopenbtn {
  cursor: pointer;
  padding: -1px;
  display: block;
  top: 50%;
  height: 45px;
  justify-content: center;
  width: 25px;
  border-radius: 0px 155px 155px 0px;
  background: rgb(255, 255, 255);
  position: absolute;
  z-index: 201;
  border: 0px;
  box-shadow: 2px 2px 1px rgb(0 0 0 / 25%)
}

.legend-key {
  display: inline-block;
  border-radius: 20%;
  width: 10px;
  height: 10px;
  margin-right: 5px;
}

.legend-content {
  min-height: 100%;
  min-width: 100%;
  border-radius: 0.5em 0.5em 0.5em 0.5em;
  background: rgb(255, 255, 255);
  position: absolute;
  z-index: 200;
  box-shadow: 2px 2px 1px rgb(0, 0, 0, 0.25);
  padding-top: 15%;
  padding-left: 25%;
}

.legend-content span {
  align-items: left;
}

.downloadbtnall {
  z-index: 200;
  left: 20px;
  bottom: 10%;
  position: absolute;
  background-color: #1a1d17;
  color: #ffffff;
  border: none;
  border-radius: 0.5em 0 0 0.5em;
  padding: 0.4em;
  padding-left: 0.5em;
  padding-right: 0.5em;
}

.showbiomassbtn {
  z-index: 200;
  left: 20px;
  top: 21rem;
  position: absolute;
  background-color: #1a1d17;
  color: #ffffff;
  border: none;
  border-radius: 0.5em 0 0 0.5em;
  padding: 0.4em;
  padding-left: 0.5em;
  padding-right: 0.5em;
}

.mapbox-overlay-buttons button {
  border: none;
  margin-right: 2%;
  margin-top: 5%;
  padding: 0.5em 0.7em 0.5em 0.7em;
  background-color: #357960;
  width: 15%;
  box-sizing: border-box;
  border-radius: 0.5em;

  font-style: normal;
  font-weight: bold;
  letter-spacing: -0.015em;
  color: #ffffff;
  box-shadow: 2px 2px 1px rgb(0, 0, 0, 0.25);
}

.mapbox-overlay-buttons :hover {
  background: #798777;
  cursor: pointer;
}

.side-menu-panel {
  overflow: hidden;
  transition: all .5s ease-in-out;
  height: 0%;
}

.side-menu-panel input {
  display: none;
}

.side-menu-panel label {
  display: inline-block;
  padding: 5px 10px;
  cursor: pointer;
}

#disabled-landcover:before {
  content: '';
  border: none;
  width: 20px;
  background: #B5B5B5;
  box-shadow: inset 1px 1px 4px rgba(0, 0, 0, 0.5);
  border-radius: 25%;
  height: 20px;
  margin-right: 10px;
  display: inline-block;
  vertical-align: top;
}

.side-menu-panel label span {
  position: relative;
  line-height: 22px;
}

.side-menu-panel label span:before {
  content: '';
  border: none;
  width: 20px;
  background: #E7E7E7;
  box-shadow: inset 1px 1px 4px rgba(0, 0, 0, 0.5);
  border-radius: 25%;
  height: 20px;
  margin-right: 10px;
  display: inline-block;
  vertical-align: top;
}

.side-menu-panel label span:after {
  content: '';
  background: #357960;
  border-radius: 25%;
  width: 20px;
  height: 20px;
  position: absolute;
  top: -2px;
  left: 0px;
  transition: 300ms;
  opacity: 0;
}

.side-menu-panel label input:checked+span:after {
  opacity: 1;
}


.side-menu-open-btn {
  cursor: pointer;
  z-index: 99;
  position: absolute;
  top: 15px;
  left: 9px;
  background: rgb(255, 255, 255);
  border: none;
  border-radius: 10px 10px;
  padding: 1em;
}

.side-menu-div {
  z-index: 100;
  position: absolute;
  width: 350px;
  height: 100%;
  left: -350px;
  background: rgb(255, 255, 255);
  border: none;
  border-radius: 0px 10px;
  text-align: left;
  padding: 10px;
  overflow: overlay;
}

.biomass-data {
  display: flex;
  justify-content: space-between;
  padding: 5px;
  z-index: 100;
  position: absolute;
  min-width: 31em;
  right: 2%;
  bottom: 15%;
  gap: 10px;
  border: none;
  border-radius: 10px 10px;
  background: rgb(255, 255, 255);
  box-shadow: 2px 2px 1px rgb(0, 0, 0, 0.25);
  /* background-image: linear-gradient(0deg, #E5F4E4 20%, transparent 20%, transparent 40%, #E5F4E4 40%, #E5F4E4 60%, transparent 60%, transparent 80%, #E5F4E4 80%, #E5F4E4 100%, rgb(255, 255, 255) 100%); */
}

.biomass-data table {
  box-sizing:unset;
}

.biomass-data th {
  border: none;
}

.biomass-data td {
  text-align: center; 
  border: none; 
}

.biomass-data td.title{
  border: none; 
  text-align: left; 
}

tr:nth-child(odd) {
  background-color: #E5F4E4;
}

.download-csv-panel {
  z-index: 101;
  position: absolute;
  width: 20%;
  height: 70%;
  right: 25%;
  bottom: 15%;
  border: none;
  border-radius: 10px 10px;
  background: rgb(255, 255, 255);
  box-shadow: 2px 2px 1px rgb(0, 0, 0, 0.25);
}

.download-csv-content {
  position: absolute;
  bottom: 30%;
  left: 26%;
}

.side-menu-header {
  display: flex;
}

.Arrow {
  display: inline;
  white-space: nowrap;
  width: 20px;
  height: 20px;
  margin-right: 5%;
  cursor: pointer;
}

.side-menu-header h4 {
  display: inline;
  white-space: nowrap;
}

.close-data {
  max-height: 40px;
  min-width: 40px;
  border: none;
  background-color: #bad3b8;
  border-radius: 0.5em;
  cursor: pointer;
}

.title {
  display: flex;
  justify-content: space-between; 
}
.info-icon {
    width: 24px;
    height: 24px;
    margin-left: auto; /* Make sure the icon right-aligned */
    z-index: 10;
}

.tooltip {
    position: absolute;
    border-radius: 10px;
    border: 0.5px solid var(--dark-surface, #4D4D4D);
    background: var(--light-surface, #FFF);
    display: inline-block;
    padding: 5px 20px;
}
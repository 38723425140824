.tab-body {
  margin-left: 4em;
}

.tab-body > h1 {
  font-size: 25px;
  text-align: left;
  color: #3D3D3D;
  margin-bottom: 1em;
}

.tab-body > h2 {
  font-size: 20px;
  text-align: left;
  font-weight: bold;
  line-height: 23px;
  color: #3D3D3D;
}
.image-gallery__title {
    display: flex;
flex-direction: row;
justify-content: space-between;
align-items: flex-start;
padding: 32px 16px 16px;

position: absolute;
width: 1312px;
height: 100px;
left: 89px;
top: 64px;
}
    
.image-gallery__title h1 {
    font-style: normal;
    width: 15em;
    font-weight: 700;
    letter-spacing: -0.015em;
    color: #3D3D3D;
    font-size: 2em;
  }
  
  .image-gallery__title-import{
    display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px 17px;
  
  position: static;
  width: 127px;
  height: 36px;
  left: 8px;
  top: 8px;
  
  background: rgba(210, 210, 210, 0.19);
  /* dark surface */
  
  border: 2px solid #4D4D4D;
  box-sizing: border-box;
  border-radius: 15px;
  
  /* Inside auto layout */
  
  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 0px 79px;
  cursor: pointer;
  justify-content: space-around;
    align-items: center;
  }
  .image-gallery__title-import:hover{
      background-color: #357960;
      color: white;
  }
.dynamic-textbox-list {
  position: relative;
}

.dynamic-textbox-input-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.dynamic-textbox-input-container input {
  position: initial;
  border-color: var(--primary);
  border-radius: 5px;
  border-width: 1px;
  border-style: solid;
  width: 100%;
  height: 1.5em;
  margin-bottom: 0.5em;
}

.dynamic-textbox-add-btn {
  border-color: var(--dark-subtle-surface);
  border-radius: 5px;
  border-width: 1px;
  border-style: solid;
  width: 100%;
}
.gedi-details-body {
  display: block;
  flex-direction: row;
  margin-left: 5em;
  margin-right: 5em;
  height: 100%;
}

h1 {
  font-style: normal;
  font-weight: 700;
  letter-spacing: -0.015em;
  color: #3D3D3D;
  text-align: left;
  font-size: 2em;
}

.gedi-details {
  width: 100%;
  height: 100%;
}

.toggle-row {
  padding-top: 2.25%;
  padding-left: 2.25%;
  width: 100%;
  height: 5em;
  border-radius: 15px;
  margin-bottom: 0.5%;

  background-color: #E5F4E4;
  color: #357960;
}

.toggle-row span {
  float: left;

  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 23px;
  letter-spacing: -0.015em;
}

.toggle-row img {
  float: right;
}

.detail-box {
  border: 3px solid #357960;
  padding: 2.25%;
  width: 100%;
  min-height: 10%;
  border-radius: 15px;
  text-align: left;
}

.detail-box span {

  font-family: 'Roboto';
  font-style: normal;
  line-height: 26px;
  letter-spacing: -0.015em;
}
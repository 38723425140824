.landowner-gallery-container {
  display: grid;
  column-gap: 1em;
  row-gap: 1em;
  grid-template-rows: auto;
  grid-template-columns: repeat(3, 1fr);
  padding-top: 1.5em;
  padding-bottom: 1em;
}

.landowner-card {
  position: relative;
  background: var(--light-primary);
  box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.25);
  border-radius: 15px;
  width: 392px;
  /* max-height: 14em; */
  height: auto;
  text-align: left;
  cursor: pointer;
}

.landowner-card:hover {
  opacity:0.4; 
}

.landowner-card h3 {
  color: var(--primary);
  margin-bottom: 0.5em;
}

.landowner-card-details {
  padding: 1.2em 2em 0 2em;
}

.landowner-card-details span {
  display: block;
  width: 100%;
  height: 2em;
  color: var(--dark-surface);
  font-weight: 300;
}

.landowner-card-details img {
  width: 24px;
}

.landowner-card-observations {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-content: flex-start;
}

.landowner-characteristic-child {
  flex: 1 0 calc(25% - 20px);
  max-width: calc(25% - 20px);
  height: auto;
  margin: 10px;
  text-align: center;
}

.landowner-characteristic-child p {
  font-family: Roboto;
  font-style: normal;
  font-weight: 200;
  font-size: 36px;
  line-height: 42px;
  text-align: center;
  letter-spacing: -0.015em;

  /* dark surface */

  color: #4D4D4D;
}

.landowner-characteristic-child h4 {
  font-weight: normal;
}

.landowner-card-notif {
  position: absolute;
  background-color: var(--highlight);
  border-radius: 50%;
  display: inline-block;

  top: 1.9em;
  left: 1em;
  height: 8px;
  width: 8px;
}

.landowner-gallery-list-container{
  display: flex;

  margin: 10px;
}

.list-row-selected{
  background: red;
}
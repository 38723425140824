.selection-gallery {
    /* Box Model */
  
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 8px;
  
    position: relative;
    width: 90%;
    height: 100%;
    left: 5%;
    margin-bottom: 3em;
  }

  
  
  .selection-gallery-heading {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 8px;
  
    position: static;
    width: 1312px;
    height: 36px;
    left: 8px;
    top: 8px;
  
  
    /* Inside auto layout */
  
    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;
    margin: 8px 30px;
    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 19px;
    /* identical to box height */
  
  
    /* dark surface */
  
    color: #4D4D4D;
  
  }
  
  .selection-gallery__collection {
    /* flexbox */
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;
    align-items: flex-start;
    align-content: flex-start;
    margin-top: 0;
    /* Box Model */
    width: 100%;
    height: auto;
    margin-left: 5px;
  }
  
  .selection-gallery__item {
    /* grow shrink base_size*/
    flex: 0 1 auto;
    text-decoration: none;
    cursor: pointer;
    margin: 2rem;
    width: 11.875rem;
    height: 5.75rem;
    background: #E7E7E7;
    border-radius: 15px;
  }
  
  .selection-gallery__item2 {
    display: flex;
    /* flex-direction: column;
    justify-content: center;
    align-items: flex-start; */
    padding: 16px;
  
    position: static;
    width: 100%;
    height: 80px;
    left: 0px;
    top: 336px;
    background: #D8EDD4;
  
    /* Inside auto layout */
  
    flex: none;
    order: 4;
    align-self: stretch;
    flex-grow: 0;
    margin: 4px 0px;
  }
  
  .selection-gallery__item p {
    margin: 1em;
    font-size: 3em;
    color: #357960;
    text-align: center;
  }
  
  .selection-gallery__item__content {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 23px;
    text-align: center;
    letter-spacing: -0.015em;
    color: #357960;
    cursor: pointer;
  }
  
  .selection-gallery__item__content div {
    text-align: left; /* Set to center-align text */
    width: 100%; /* Ensure text takes up full width */
    /* background-color: #DA4167; */
  }
  
  .selection-gallery-text-container {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
  
  .selection-gallery__item__content img {
    object-fit: fill;
    margin-top: 1em;
    width: 75%;
    height: 63%;
  }
  
  /* .selection-gallery__item__caption {
    object-fit: fill;
    font-weight: 500;
    font-style: normal;
    text-align: center;
  } */
  
  .selection-gallery__item__caption:hover {
    cursor: pointer;
  }
  
  .add-button,
  .add-button::after {
    font-weight: 700;
    color: #FFF !important;
    font-size: 50px;
  }
  
  .add-button:hover {
    cursor: pointer;
  }
  
  .selection-card-forest {
    /* grow shrink base_size*/
    position: relative;
    flex: 0 1 auto;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    align-items: stretch;
  
    width: 82rem;
    height: 4rem;
  
    padding: 1rem;
    background: var(--light-primary);
    /* cursor: pointer; */
  }
  .selection-card-forest-icons {
    display: flex;
    flex-direction: row;
    /* margin: 0.5rem;
    margin-bottom:0rem; */
  }
  
  .selection-card-forest-icon {
    width: 24px;
    height: 24px;
  }
  
  .selection-card-forest-content {
    position: relative;
    /* margin: 1rem; */
    flex-grow: 1;
    margin-top: -50px;
    margin-left: 40px;
  }
  
  .selection-card-forest-content p {
    margin: 0.5rem;
    margin-block: 1rem;
    text-align: left
  }
  
  .selection-card-forest-content span {
    color: #4D4D4D;

  }
  
  .selection-card-forest-title {
    margin: 0.5rem;
    text-align: left;
    cursor: pointer;
    overflow: hidden;
    text-overflow: ellipsis;
    color: #357960;
    font-size: 20px;
  }
  
  .selection-card-forest-title:hover {
    text-decoration: underline;
    opacity: 0.4;
  }
  
  .selection-card-forest-timestamp {
    /* margin: 1rem; */
    text-align: right;
    color: var(--dark-subtle-surface);
    display: flex;
    flex-direction: column;
    font-size: 14px;
  }
  
  .observations-gallery-forest {
    /* display:flex;
    flex-direction: row;
    flex-wrap: wrap; */
    /* margin-left: -29px; */
    display: grid;
    grid-row-gap: 1em;
    row-gap: 1em;
    grid-template-rows: auto;
    padding-top: 1.5em;
  }
  
  .sort {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 8px;
  
    position: static;
    width: 31px;
    height: 31px;
    top: 2.5px;
    margin-left: 10px;
    margin-right: 20px;
  
  
  
    /* Inside auto layout */
  
    flex: none;
    order: 1;
    flex-grow: 0;
    background-color: white;
    border-color: transparent;
    cursor: pointer;
  }
  
  .sort-icon {
    position: static;
    width: 21px;
    height: 21px;
    left: 8px;
    top: 8px;
  
  
    /* Inside auto layout */
  
    flex: none;
    order: 0;
    flex-grow: 0;
    margin: 0px -1px;
  }
  
  .last-updated {
    font-family: Roboto;
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 140%;
    /* or 22px */
  
    letter-spacing: 0.005em;
  
    /* dark subtle surface */
  
    color: #B5B5B5;
  }
  
  
  .create-button {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0px 30px;
  
    position: static;
    width: 0px;
    height: 40px;
    left: 13px;
    top: -2px;
  
    /* primary */
  
    background: #FFFFFF;
    border-radius: 10px;
  
    /* Inside auto layout */
  
    flex: none;
    order: 2;
    flex-grow: 0;
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 23px;
    text-align: center;
    letter-spacing: -0.015em;
  
    /* light surface */
  
    color: #FFFFFF;
    cursor: pointer;
  }
  
  .selection-gallery__item__context_menu {
    margin-left: auto;
    cursor: pointer;
  }

    
  .selection-gallery-forest__item__context_menu {
    margin-left: auto;
    cursor: pointer;
    z-index: 9999;
    width: 150px;
  }
  
  .selection-card .selection-gallery__item__context_menu {
    right: 0;
    cursor: pointer;
    display: inline-block;
    position: absolute;
  }

  .selection-card .selection-gallery-forest__item__context_menu {
    right: 30px;
    cursor: pointer;
    display: inline-block;
    position: absolute;
  }

  .selection-gallery__item__context_menu-img {
    width: auto;
    margin: 13px;
  }
  
  .selection-gallery-forest__item__context_menu-img {
    width: auto;
    margin-left: 70%;
    padding-top: 20px;

  }
  
  .context-menu {
    position: absolute;
    right: 5%;
    background-color: #FFFFFF;
    display: none;
    width: 150px;
    height: 50px;
    border-radius: 10px;
  }
  
  .selection-card .context-menu {
    background-color: #FFFFFF;
    display: none;
    width: 150px;
    height: 50px;
    border-radius: 10px;
    position: absolute;
    z-index: 500;
  }
  
  .context-menu button {
    background: #FFFFFF;
    border: 1px solid #E7E7E7;
    width: 200px;
    height: 32px;
    padding-left: 10px;
    cursor: pointer;
    text-align: left;
    color: var(--dark-surface, #4D4D4D);
    font-size: 16px;
    font-style: normal;
    font-weight: 300;
    line-height: 140%;
    letter-spacing: 0.08px;
  }
  
  .context-menu button:hover {
    background: #a5a5a5;
  }
  
  
  .context-menu-property {
    background-color: #FFFFFF;
    display: none;
    width: 150px;
    border-radius: 10px;
  }
  
  .selection-card .context-menu-property {
    background-color: #FFFFFF;
    display: none;
    width: 150px;
  
    border-radius: 10px;
    position: absolute;
    z-index:500;
  }
  
  .context-menu-property button{
    background: #FFFFFF;
    border: 1px solid #E7E7E7;  
    width: 200px;
    height: 32px;
    padding-left: 10px;
    cursor: pointer;
    text-align: left;
    color: var(--dark-surface, #4D4D4D);
    font-size: 16px;
    font-style: normal;
    font-weight: 300;
    line-height: 140%;
    letter-spacing: 0.08px;
  }
  
  .context-menu-property button:hover{
    background: #a5a5a5;
  }
  
  .red-text{
    color: #DA4167 
  }

  .loader-context-menu {
    display: flex;
    align-items: flex-start;
  }
  
  .forest-loader {
    position: absolute;
    margin-right: 96%;
    margin-top: 20px;
    width: 30px;
    height: 30px;
    border: 3px solid #515151;
    border-bottom-color: transparent;
    border-radius: 50%;
    display: inline-block;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
  }
  
  .selected-caption {
    color: white;
  }
  
  .selection-gallery-property {
    /* Box Model */
  
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 8px;
  
    position: relative;
    width: 100%;
    height: 100%;
    left: 5%;
    margin-bottom: 3em;
  }

  .title-resolution-container {
    display: flex;
    align-items: baseline;
  }
  
  .resolution-label {
    margin-right: 30px;
    color:#4D4D4D
  }

  .content-container {
    display: flex;
    margin-top: -15px;
  }


.report-display-page {
    text-align: start;
    margin-top: -5.8em;
    margin-left: 6%;
    text-align: start;
    color: #3D3D3D;
    letter-spacing: -0.015em;
    margin-top: 2%;
}

.report-display-page__title {
    line-height: 2em;
}

.report-display-page__title h1 {
    font-style: normal;
    width: 30em;
    font-weight: bold;
    color: #3D3D3D;
    font-size: 2em;
}

.report-display-page__project__details p, .carbon-report p {
    line-height: 0.7em;
    font-size: 1.1em;
    color: #3A3A32;
    font-style: normal;
    font-weight: 500;
    margin-top: 2%;
}

.line {
    width: 90%;
    color: #7c9473b0;
    margin-left: 0;
}

.carbon-report img {
    width: 100%;
}

.carbon-report h3 {
    font-size: 1.5em;
}

.name {
    font-weight: bold;
}
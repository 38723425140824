.report-chart-delta-delta {
  color: var(--dark-surface);
  display: flex;
  align-items: center;
  font-size: 24px;
  margin-left: 10px;
}

.delta-link {
  margin-left: 8px;
}

.chart-title-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 40px
}


.report-chart-link__page__header {
  margin-bottom: 30px;
  text-align: left;
}

.report-chart-link__page__header h3 {
  font-size: 30px;
  color: var(--dark-surface);
}

.report-chart-link__subpage__header {
  text-align: center;
}

.report-chart-link__subpage__header h3 {
  font-size: 26px;
  color: var(--dark-surface);
}

.report-chart-link__page {
  text-align: left;
  margin: 1em 0 1em 5rem;
}

.chart-container {
  text-align: center;
  margin-top: 10px;
}

.chart-heading-delta {
  font-size: 24px;
  color: var(--dark-subtle-surface);
  margin: 0;
  text-decoration: none;
}

.centered-chart-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 470px;
  width: 900px;
  margin: 10px auto 0;
}

.popup-message {
  position: absolute;
  top: 140px;
  /* increase to go down*/
  right: 260px;
  /* increase to go towards left */
  border: 0.5px solid var(--dark-surface);
  border-radius: 10px;
  background: var(--light-surface);
  padding: 10px;
  display: inline-flex;
  padding: 5px 20px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  display: none;
}

.popup-message.active {
  display: block;
}

.dropdown-container {
  display: flex;
  width: 81px;
  height: 40px;
  padding: 4.8px 0.752px 4.8px 0px;
  justify-content: center;
  align-items: center;
  margin: 10px auto 0; 
}

.year-dropdown {
  margin: 10px;
  border-radius: 10px;
  padding: 4.8px 8px 4.8px 4.8px;
}

.chart-description {
  margin-top: 10px;
}
.landowner-new__form {
  position: relative;
}

.landowner-new__form__body {
  margin-top: 3em;
  display: grid;
  grid-row-gap: 2em;
  grid-column-gap: 2em;
}

.landowner-new_form_last_row {
  display: flex;
  grid-column: 1/3;
}

.landowner-new__input-block input {
  border: 1px solid #969696;
  box-sizing: border-box;
  width: 100%;
  height: 2em;
  font-size: 16px;
  border-radius: 10px;
  border-color: var(--primary);
  margin-top: 10px;
  padding-left: 10px;

}

.landowner-new__input-block {
  font-size: 18px;
  text-align: left;
  color: var(--dark-surface)
}



.landowner-new__button {
  margin-top: 10%;
  text-align: left;
  display: flex;
  border-radius: 10px;
  align-items: left;
  justify-content: center;
  position: relative;
  font-size: 20px;
}

.landowner-new__create-btn {
  background-color: var(--primary);
  color: #FFF;
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 23px;
  text-align: center;
  letter-spacing: -0.015em;

  width: 18.875em;
  height: 2.5em;
  border: none;
  border-radius: 10px;

  cursor: pointer;
}

.landowner-new__create-btn:hover {
  opacity: 0.4; 
}

.landowner-new__cancel-btn {
  background-color: var(--light-primary);
  color: var(--primary);
  margin-left: 2em;
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 23px;
  text-align: center;
  letter-spacing: -0.015em;

  width: 9.75em;
  height: 2.5em;
  border: none;
  border-radius: 10px;

  cursor: pointer;
}

.landowner-new__cancel-btn:hover {
  opacity: 0.4; 
}

.landowner-new__warning-text {
  position: absolute;
  color: red;
  text-align: left;
  top: -2em;
}

.landowner-new_text-limit-colour {
  color: #DA4167;
}
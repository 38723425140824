.selection-gallery__item_property {
  display: flex;
  padding: 16px;
  position: static;
  width: 100%;
  height: 80px;
  left: 0px;
  top: 336px;
  background: #E5F4E4;
  flex: none;
  order: 4;
  align-self: stretch;
  flex-grow: 0;
  margin: 4px 0px;
}

.selection-gallery__item_property .selection-gallery__item__content {
  width: 88%;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 23px;
  text-align: left;
  letter-spacing: -0.015em;
  color: #357960;
  cursor: pointer;
}

/* .selection-gallery__item__content div {
  text-align: left; 
  width: 70%;
  background-color: #DA4167;
} */

.selection-gallery-text-container {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.selection-gallery-text-property-container {
  /* width: 50%; */
  display: flex;
  /* padding-right: 2em; */
  justify-content: space-between;
  text-align: left;
  /* margin-right: 9em; */
  /* background-color: #357960; */
}

.selection-gallery-text-property-container .property-info {
  width: calc(20% - 10px);
  padding-top: 10px;
  /* background-color: #a5a5a5; */
}

.selection-gallery__item__content img {
  object-fit: fill;
  margin-top: 1em;
  width: 75%;
  height: 63%;
}

/* .selection-gallery__item__caption {
  object-fit: fill;
  font-weight: 500;
  font-style: normal;
  text-align: center;
} */

.selection-gallery__item__caption:hover {
  cursor: pointer;
}

.add-button,
.add-button::after {
  font-weight: 700;
  color: #FFF !important;
  font-size: 50px;
}

.add-button:hover {
  cursor: pointer;
}

.selection-card {
  /* grow shrink base_size*/
  position: relative;

  flex: 0 1 auto;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: stretch;

  width: 9.875rem;
  height: 14.5rem;

  padding: 1rem;
  background: var(--light-primary);
  border-radius: 15px;
  /* cursor: pointer; */
}

.selection-card-icons {
  display: flex;
  flex-direction: row;
  /* margin: 0.5rem;
  margin-bottom:0rem; */
}

.selection-card-icon {
  width: 24px;
  height: 24px;
}

.selection-card-content {
  /* margin: 1rem; */
  flex-grow: 1;
}

.selection-card-content p {
  margin: 0.5rem;
  margin-block: 1rem;
  text-align: left
}

.selection-card-content span {
  color: var(--dark-subtle-surface);
  display: block;
  margin-top: 10px;
  font-weight: 400;
  font-size: 14px;
}

.selection-card-title {
  margin: 0.5rem;
  text-align: left;
  cursor: pointer;
  overflow: hidden;
  text-overflow: ellipsis;
}

.selection-card-title:hover {
  text-decoration: underline;
  opacity: 0.4;
}

.selection-card-timestamp {
  /* margin: 1rem; */
  text-align: right;
  color: var(--dark-subtle-surface);
  display: flex;
  flex-direction: column;
  font-size: 14px;
}

.observations-gallery {
  /* display:flex;
  flex-direction: row;
  flex-wrap: wrap; */
  /* margin-left: -29px; */
  display: grid;
  grid-column-gap: 1em;
  column-gap: 1em;
  grid-row-gap: 1em;
  row-gap: 1em;
  grid-template-rows: auto;
  grid-template-columns: repeat(6, 1fr);
  padding-top: 1.5em;
  padding-bottom: 1em;
}

.sort {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 8px;

  position: static;
  width: 31px;
  height: 31px;
  top: 2.5px;
  margin-left: 10px;
  margin-right: 20px;



  /* Inside auto layout */

  flex: none;
  order: 1;
  flex-grow: 0;
  background-color: white;
  border-color: transparent;
  cursor: pointer;
}

.sort-icon {
  position: static;
  width: 21px;
  height: 21px;
  left: 8px;
  top: 8px;


  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 0px -1px;
}

.last-updated {
  font-family: Roboto;
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 140%;
  /* or 22px */

  letter-spacing: 0.005em;

  /* dark subtle surface */

  color: #B5B5B5;
}


.create-button {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0px 30px;

  position: static;
  width: 0px;
  height: 40px;
  left: 13px;
  top: -2px;

  /* primary */

  background: #FFFFFF;
  border-radius: 10px;

  /* Inside auto layout */

  flex: none;
  order: 2;
  flex-grow: 0;
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 23px;
  text-align: center;
  letter-spacing: -0.015em;

  /* light surface */

  color: #FFFFFF;
  cursor: pointer;
}

.selection-gallery__item__context_menu {
  margin-left: auto;
  cursor: pointer;
}

.selection-card .selection-gallery__item__context_menu {
  right: 0;
  cursor: pointer;
  display: inline-block;
  position: absolute;
}

.selection-gallery__item__context_menu-img {
  width: auto;
  margin: 13px;
}

.context-menu {
  background-color: #FFFFFF;
  display: none;
  width: 150px;
  height: 50px;
  border-radius: 10px;
}

.selection-card .context-menu {
  background-color: #FFFFFF;
  display: none;
  width: 150px;
  height: 50px;
  border-radius: 10px;
  position: absolute;
  z-index: 500;
}

.context-menu button {
  background: #FFFFFF;
  border: 1px solid #E7E7E7;
  width: 200px;
  height: 32px;
  padding-left: 10px;
  cursor: pointer;
  text-align: left;
  color: var(--dark-surface, #4D4D4D);
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 140%;
  letter-spacing: 0.08px;
}

.context-menu button:hover {
  background: #a5a5a5;
}


.context-menu-property {
  background-color: #FFFFFF;
  display: none;
  width: 150px;
  border-radius: 10px;
}

.selection-card .context-menu-property {
  background-color: #FFFFFF;
  display: none;
  width: 150px;

  border-radius: 10px;
  position: absolute;
  z-index: 500;
}

.context-menu-property button {
  background: #FFFFFF;
  border: 1px solid #E7E7E7;
  width: 200px;
  height: 32px;
  padding-left: 10px;
  cursor: pointer;
  text-align: left;
  color: var(--dark-surface, #4D4D4D);
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 140%;
  letter-spacing: 0.08px;
}

.context-menu-property button:hover {
  background: #a5a5a5;
}

.red-text {
  color: #DA4167
}

.loader {
  margin-top: 2px;
  width: 30px;
  height: 30px;
  border: 3px solid #515151;
  border-bottom-color: transparent;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

.selected-caption {
  color: white;
}

.selection-gallery-property {
  /* Box Model */

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 8px;

  position: relative;
  width: 100%;
  height: 100%;
  left: 5%;
  margin-bottom: 3em;
}
.outer-card {
  background: #676767;
  border-radius: 15px;
  width: 8em;
  min-width: 8em;
  height: 12em;
  margin-left: 1.5em;
}

.outer-card-popup {
  background: #676767;
  border-radius: 15px;
  width: 8em;
  height: 12em;
}

.inner-card {
  display: flex;
  border-radius: 15px;
  width: 5.7em;
  height: 6.7em;
  background: #BAC7A7;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  margin-left: auto;
  margin-right: auto;
  margin-top: 1.2em;
}

.bottom-text {
  margin-top: 8px;
  margin-left: auto;
  margin-right: auto;
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 17px;
  line-height: 21px;
  letter-spacing: -0.015em;
  color: #FFFFFF;
  text-align: center;
  width: 6em;
}

.bottom-text-popup {
  margin-top: 8px;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 15px;
  letter-spacing: -0.015em;
  color: #CFE1CE;
  text-align: center;
  width: 8em;
}

.monitoring-gallery {
  overflow-x: auto;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  box-sizing: border-box;
  max-width: 31.5em;
  margin-left: 1em;
  padding-bottom: 0.5em;
}

.monitoring-gallery > :first-child {
  margin-left: 0;
}

.monitoring-gallery::-webkit-scrollbar {
  width: 10px;
}

/* Track */
.monitoring-gallery::-webkit-scrollbar-track {
  background: transparent; 
}
 
/* Handle */
.monitoring-gallery::-webkit-scrollbar-thumb {
  background: #b3c4b2;
  border-radius: 5px;
}

/* Handle on hover */
.monitoring-gallery::-webkit-scrollbar-thumb:hover {
  background: #8fa58e; 
}

.new-report-btn {
  width: 100%;
  height: 100%;
  background: none;
}
.ndvi-gallery__title{
    margin-top: -1.8em;
    width: 42em;
    margin-left: -1.1%;
}
  
.ndvi-gallery__title h1 {
    font-style: normal;
    width: 22.5em;
    font-weight: bold;
    letter-spacing: -0.015em;
    color: #3D3D3D;
    font-size: 2em;
}
  
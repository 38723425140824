.background-card {
  width: 8em;
  min-width: 8em;
  height: 12em;
  background: #999999;
  margin-left: 1.5em;
}

.observation-image {
  width: 100%;
  height: 100%;
}

.observation-gallery {
  overflow-x: auto;
  overflow-y: visible;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  max-width: 29.7em;
  padding-bottom: 0.5em;
  margin-left: 1em;
}

.observation-gallery > :first-child {
  margin-left: 0;
}

.observation-gallery::-webkit-scrollbar {
  width: 10px;
}

/* Track */
.observation-gallery::-webkit-scrollbar-track {
  background: transparent; 
}
 
/* Handle */
.observation-gallery::-webkit-scrollbar-thumb {
  background: #b3c4b2;
  border-radius: 5px;
}

/* Handle on hover */
.observation-gallery::-webkit-scrollbar-thumb:hover {
  background: #8fa58e; 
}
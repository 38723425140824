 /* Style inputs with type="text", select elements and textareas */
.header{
  
  text-align: left;
  /* width: 1000px; */
  height: 20px;

  /* Heading 2 <h2> */

font-family: 'Roboto';
font-style: normal;
font-weight: 700;
font-size: 19px;
line-height: 30px;
margin-left: 70px;
margin-top: 25px;

/* dark surface */

color: #4D4D4D;


/* Inside auto layout */

flex: none;
order: 0;
flex-grow: 0;

}

.fixed_form{
  margin-bottom: 4em;
}

.header_info{
  width: 100%;
  height: 20px;

  /* Heading 2 <h2> */

font-family: 'Roboto';
font-style: normal;
font-weight: 700;
font-size: 26px;
line-height: 30px;
letter-spacing: -0.005em;
margin-top: 40px;
text-align: left;
margin-left: 33.4px;

/* dark surface */

color: #4D4D4D;


/* Inside auto layout */

flex: none;
order: 0;
flex-grow: 0;
}



.Email_Class, .Name_Class {
  position: relative;
  align-items: center;

  border-radius: 10px;
  padding: 10px;
  padding-block: 1em;
  margin: 16px;
  margin-left: 10px;
  /* margin-right: 23px; */

  /* font-weight: bold; */
  font-size: 16px;
  color: #357960;
  width: 348px;
  height: 32px;
  margin-bottom: 60px;
}

.Full_name, .email_written, .help_written {
  margin-left: 5%;
  color: #4D4D4D;
  text-align: left;
}

/* .email_written{
  margin-left: -180px;
  color: #4D4D4D;
}

.help_written{
  margin-left: -100px;
  color: #4D4D4D;

} */

/* .Name_Class{
  align-items: center;
  border-radius: 10px;
  padding: 16px;
  padding-block: 1em;
  margin-top: 2vh;
  margin-left: 10px;
  margin-bottom: 60px;
  font-size: 16px;
  color: #357960;
  width: 348px;
  height: 32px;
} */

.message{
  position: relative;
  align-items: center;

  border-radius: 10px;
  padding: 10px;
  padding-block: 1em;
  margin: 16px;
  margin-left: 10px;
  /* margin-right: 23px; */

  /* font-weight: bold; */
  font-size: 16px;
  color: #357960;
  width: 348px;
  height: 15vh;
  margin-bottom: 60px;
}


  
.send-message-btn {
  padding: 15px;
  margin-top: 10px;
  border: 0px;
  cursor: pointer;
  background: #357960;
  box-sizing: border-box;
  border-radius: 10px;
  left: 0;

  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 23px;
  text-align: center;
  letter-spacing: -0.015em;

  cursor: pointer;
  color: #ffffff;
}

.send-message-btn:hover {
  background: #9ebbb0;
  cursor: pointer;
}

.send-message {
  margin-top: 60px;
  display: block;
}
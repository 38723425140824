.regions-list input {
    display: block;
    margin-top: 10px;
}

.regions-list {
    background-color: rgb(110, 175, 121);
    padding: 10px;
    border-radius: 10px;
    width: 300px;
    max-height: 200px;
    overflow-y: scroll;
}

.project-new__form__body input {
    width: auto;
}
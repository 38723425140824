.property-new-body {
  position: relative;
  margin-left: 5rem;
  margin-right: 5rem;
  margin-top: 1.5rem;
  color: var(--dark-surface);
  height: 100%;
}

.property-new-body > h3 {
  text-align: left;
}

.property-new-form {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  height: 100%;
  justify-content: space-between;
}

/* .header-container {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.header-container img.create-icon {
  margin-left: 15px;
} */

.property-new-inputs {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.property-label-inputs-container {
  width: 45%;
  text-align: left;
}

.property-new-form-body {
  display: grid;
  grid-template-rows: 1fr;
  row-gap: 20px;
}

.property-input-and-label {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.property-new-text-input {
  border: 1px solid var(--primary);
  border-radius: 10px;
  box-sizing: border-box;
  height: 2em;
  width: 65%;
  font-family: Roboto;
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 140%;
  letter-spacing: 0.005em;

  color: var(--dark-surface);
}

.property-new-boundary {
  font-weight: 500;
  display: flex;
  flex-direction: column;
  height: 350px;
  justify-content: space-between;
}

.property-new-boundary p {
  font-weight: 500;
  font-size: 20px;
}

.property-new-mapbox-container {
  width: 90%;
  min-height: 35rem;
  max-height: 35rem;
  margin-top: 1rem;

  margin-left: auto;
  margin-right: auto;
}

.property-new-button-container {
  margin-top: 1em;
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100%;
}

.btn {
  border-radius: 10px;
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 23px;
  text-align: center;
  letter-spacing: -0.015em;
  border: 0;

  height: 2.05em;
  width: 11.8em;

  cursor: pointer;
}

.property-new-button-container .btn {
  justify-content: center;
}
.property-new-button-container a {
  text-decoration: none;
}

.choose-map-btn.btn {
  background-color: rgba(210, 210, 210, 0.19);
  border: 2px solid var(--dark-surface);
  border-radius: 15px;
  color: var(--dark-surface);
  font-weight: 500;
  font-size: 20px;
  width: 185px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.choose-map-btn.btn:hover {
  opacity: 0.4; 
}

.property-new-draw-button-container {
  display: flex;
  justify-content: center;
}

.property-new-btn {
  background-color: var(--light-primary);
  color: var(--primary);
}

.property-new-btn:hover {
  background-color: var(--light-primary);
  color: var(--primary);
  opacity: 0.4; 
}

.property-new-submit-btn {
  background-color: var(--primary);
  color: white;
}

.property-new-submit-btn:hover {
  background-color: var(--primary);
  color: white;
  opacity: 0.4; 
}

.property-new__warning-text {
  color: red;
  display: none;
}

.property-new_optonal-text {
  font-size: 14px;
  font-weight: 300;
  color: var(--dark-subtle-surface);
}

.property-new_text-limit-colour {
  color: #DA4167;
}
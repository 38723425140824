.line {
    width: 90%;
    color: #7c9473b0;
    margin-left: 0;
}

.open-legend {
    position: absolute;
    right: 0;
    margin-right: 10%;
}

.open-legend button {
    all: unset;
    padding: 0.3em 1.2em 0.3em 1.2em;
    right: 0;
    background: #C4C4C4;
    font-style: normal;
    font-weight: 500;
}

.lulc-report__lulc__details {
    position: absolute; 
    right: 0;
    margin-right: 10%;
    border: 0.1rem solid #7C9473;
    padding: 1em;
    margin-top: -30em;
    min-width: 9em;
}

.close-btn {
    all: unset;
    font-size: 1.8em;
    text-align: end;
    font-weight: 100;
    right: 0;
    margin-right: 8px;
    top: 2px;
    position: absolute;
    color: #3A3A32;
}



.legend-labels li {
    line-height: 30px;
    height: 1.5em;
    display: flex;
    align-items: center
}

.legend-labels li span {
    display: block;
    float: left;
    height: 16px;
    width: 30px;
    margin-right: 5px;
    margin-left: 0;
    border: 2px solid rgb(0, 0, 0);
}

.legend-gradient{
    background: rgb(0,0,0);
    height: 16.5em;
    width: 25px;
    border: 2px solid rgb(0, 0, 0);
}
.legend-subcontainer{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    margin: 1em;
}

.legend-desc-switch-btn{
    float:right;
}
.finish-form-button {
  margin-top: 2em;
  margin-left: 0%;
}

.finish-form-button button {
  height: 41px;
  width: 137px;
  background: #BAC7A7;
  float: left;
  border-radius: 0.4em;
  border: none;
  font-weight: 700;
  color: #3A3A32;
  padding: 0.5em;
}

.finish-form-button button:hover {
  background: #798777;
  cursor: pointer;
  color: #E5E4CC;
}

.display-payment-info{
  position: relative;
  width: auto;
  height: auto;

  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 25px;
  line-height: 29px;
  letter-spacing: 0.01em;

  color: #3A3A3A;
}

.accepted{
  color:#5D8A41;

}

.declined{
  color:#B73E3E
}

.request-finish__form{
  display: flex;
  flex-direction:column;
  align-items:flex-start;

}

.request-finish__form__body{
  display: flex;
  flex-direction: column;
}

.form-page-container{
  display: flex;
}

.request-form-textarea{
  position: relative;
  width: 150%;
  height: 6em;
  font-size: 17px;

  border: 1px solid #969696;
  box-sizing: border-box;
}

.request-form-field{
  margin-top:1em;

  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 22px;
  line-height: 36px;
  text-align: start;

  letter-spacing: -0.015em;

  color: #3A3A32;
}
.tab-group {
  border-radius: 20px;
  background: #E7E7E7;
  height: 2.4em;
  display: flex;
  font-size: 18px;
}

.tab-item {
  border: 1px solid #BFBFBF;
  border-radius: 20px;
  height: 100%;
  background: #E7E7E7;
  margin: none;
  font-weight: 500;
  font-size: 18px;
  line-height: 23px;
  cursor: pointer;
  width: 13.7em;
}

.tab-item:hover {
  background: #BAC7A7;
}

.selected-tab {
  background: #DCE1CE;
}
.project-region-page {

  width: 1440px;
  height: 1000px;

  background: #FFFFFF;
}

.project-region-box {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;

  position: absolute;
  width: 1314px;
  height: 754px;
  left: 64px;
  top: 64px;
}

.project-region-heading {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 32px 16px 16px 8px;

  position: static;
  width: 1314px;
  height: 80px;
  left: 0px;
  top: 0px;


  /* Inside auto layout */

  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
  margin: 0px 0px;
}

.project-region-subheading {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 8px;

  position: static;
  width: 1314px;
  height: 90px;
  left: 0px;
  top: 80px;


  /* Inside auto layout */

  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
  margin: 0px 0px;
}

.project-region-list {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;

  position: static;
  width: 1314px;
  height: 584px;
  left: 0px;
  top: 170px;


  /* Inside auto layout */

  flex: none;
  order: 2;
  align-self: stretch;
  flex-grow: 0;
  margin: 0px 0px;
}

.page-heading-title {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px;

  position: static;
  width: 267px;
  height: 46px;
  left: 8px;
  top: 25px;


  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 0px 16px;
}

.page-heading-title-text {
  position: static;
  width: 394px;
  height: 30px;
  left: 8px;
  top: 8px;

  /* heading 3 */

  font-family: Roboto;
  font-style: normal;
  font-weight: 900;
  font-size: 42px;
  line-height: 30px;
  letter-spacing: -0.005em;

  /* dark surface */

  color: #4D4D4D;


  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 0px 0px;
}

.project-name-box {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;

  position: static;
  width: 267px;
  height: 37px;
  left: 1031px;
  top: 29.5px;


  /* Inside auto layout */

  flex: none;
  order: 1;
  flex-grow: 0;
  margin: 0px 61px;
}

.project-name {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 8px;

  position: static;
  width: 132px;
  height: 37px;
  left: 0px;
  top: 0px;


  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 0px 0px;
}

.project-name-text {

  position: static;
  width: 116px;
  height: 21px;
  left: 8px;
  top: 8px;

  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 21px;
  /* identical to box height */

  letter-spacing: 0.0015em;

  color: #3A3A32;


  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 0px 10px;
}

.name-box {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 8px;

  position: static;
  width: 135px;
  height: 37px;
  left: 132px;
  top: 0px;


  /* Inside auto layout */

  flex: none;
  order: 1;
  flex-grow: 0;
  margin: 0px 0px;
}

.name-text {
  position: static;
  width: 119px;
  height: 21px;
  left: 8px;
  top: 8px;

  /* subtitle */

  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 21px;
  /* identical to box height */

  text-align: center;
  letter-spacing: 0.0015em;

  color: #3A3A32;


  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 0px 10px;
}

.subheading-title {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 8px;

  position: static;
  width: 259px;
  height: 36px;
  left: 8px;
  top: 8px;


  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 0px 24px;
}

.subheading-title-text {
  position: static;
  width: 243px;
  height: 19px;
  left: 8px;
  top: 8px;

  /* heading 4 */

  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  /* identical to box height */


  /* dark surface */

  color: #4D4D4D;


  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 0px 0px;
}

.subheading-description {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 8px;

  position: static;
  width: 1298px;
  height: 38px;
  left: 8px;
  top: 44px;


  /* Inside auto layout */

  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
  margin: 0px 24px;
}

.subheading-description-text {
  position: static;
  width: 1282px;
  height: 22px;
  right: 8px;
  top: 8px;

  /* paragraph */

  font-family: Roboto;
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 140%;
  /* or 22px */

  letter-spacing: 0.005em;

  /* dark surface */

  color: #4D4D4D;


  /* Inside auto layout */

  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
  margin: 0px 0px;
  text-align: left;
}


.observastion-button {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 16px;

  position: static;
  width: 1314px;
  height: 80px;
  left: 0px;
  top: 504px;

  background: #E5F4E4;

  /* Inside auto layout */

  flex: none;
  order: 6;
  align-self: stretch;
  flex-grow: 0;
  margin: 4px 0px;
}

/* .project-region-title {
  margin-top: 0em;
  width: 1em;
}
  
.project-title h1 {
  font-style: normal;
  width: 10em;
  font-weight: 700;
  letter-spacing: -0.015em;
  color: #3D3D3D;
}

.name {
  font-style: normal;
  font-weight: bold;
  letter-spacing: -0.015em;
  color: #3A3A32;
} */

.dropdown-label-project-details__region-label,
.dropdown-label-project-details__project-label {
  letter-spacing: -0.015em;
  line-height: 1em;
  margin-left: 2em;
  color: #3A3A32;
  font-style: normal;
  font-weight: 500;
  text-align: start;
}
#form-file-upload {
    height: 16rem;
    width: 28rem;
    max-width: 100%;
    text-align: center;
    position: relative;
  }
  
  #input-file-upload {
    display: none;
  }
  
  #label-file-upload {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-width: 2px;
    border-radius: 1rem;
    padding: 20px;
    background-color: var(--light-subtle-surface);
  }
  
  #label-file-upload.drag-active {
    background-color: #ffffff;
  }
  
  #label-file-upload p {
    font-weight: 500;
  }

  .upload-button {
    cursor: pointer;
    padding: 0.25rem;
    font-size: 16px;
    border: 2px solid var(--dark-surface);
    border-radius: 15px;
    font-family: Roboto;
    font-weight: 400;
    background-color: transparent;
    color: var(--dark-surface);
    padding: 5px 10px;

  }
  
  .upload-button:hover {
    text-decoration-line: underline;
  }
  
  #drag-file-element {
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 1rem;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
  }

  .text-icon-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100%;
    justify-content: space-evenly;
  }
  
  .text-icon-container .file-icon {
    width: fit-content;
    height: fit-content;
  }

  .question-mark {
    position: absolute;
    bottom: 15px;
    right: 15px;
  }
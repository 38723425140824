.manual-change-detection__left-image {
  width: max-content;
  object-fit: contain;
}

.manual-change-detection__right-image {
  width: max-content;
  object-fit: contain;
}

.manual-change-detection__label {
  text-align: left;
  margin-bottom: 20px;
  font-size: 20px;
  color: var(--dark-surface);
}

.manual-change-detection__page {
  text-align: left;
  margin: 1em 0 1em 5rem;
}

.manual-change-detection__page__header {
  display: flex;
  flex-wrap: nowrap;
  flex: 1 1 180px;
  text-align: left;
  white-space: nowrap;
}

.manual-change-detection__page__header--active {
  margin-right: 2rem;
}

.manual-change-detection__page__header--inactive {
  color: var(--dark-subtle-surface) !important;
  margin-right: 2rem;
}

.manual-change-detection__sub-heading {
  display: flex;
  justify-content: center;
  font-size: 24px;
  color: var(--dark-surface);
  margin-bottom: 30px;
}

.manual-change-detection__view-select {
  flex: 1 0 min-content;
  margin-bottom: 10px;
  position: relative;
}

.manual-change-detection__select-subcontainer {
  display: flex;
  width: 50%;
  margin-left: 50%;
  padding-right: 10px;
  text-align: left;
  justify-content: flex-end;
}

.ndvi-map-compare__content-wrapper {
  position: relative;
  height: 100%;
  padding-bottom: 1.5rem;
}

.manual-change-detection__selection {
  width: 100%;
  position: absolute;
  z-index: 2;
  margin-top: 11px;
  display: grid;
  margin-bottom: 1em;
  grid-template-columns: repeat(2, 1fr);
}

.manual-change-detection__select {
  flex: 1 0 min-content;
  object-fit: contain;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.manual-change-detection__details {
  display: flex;
  margin-top: 1em;
  width: 94%;
}

.manual-change-detection__left-details {
  flex: 1 0 min-content;
  object-fit: contain;
  position: relative;
  text-align: left;
}

.manual-change-detection__right-details {
  flex: 1 0 min-content;
  object-fit: contain;
  position: relative;
  text-align: left;
  left: 97px;
}

.manual-change-detection__download-button {
  height: 40px;
  width: 40px;
  padding: 8px;
  gap: 8px;
  border-radius: 5px;
  background: #FFF;
  cursor: pointer;
  margin-left: 10px;
  border: none;
}

.loading-container {
  background: #FFF;
  width: 100%;
  padding: 10px;
  box-sizing: border-box;
  border-radius: 5px;
}

.manual-change-detection__initial-popup {
  position: absolute;
  top: 55px;
  left: 50%;
  transform: translateX(-50%);
  background-color: #fff;
  padding: 10px;
  border: 0.5px solid #4D4D4D;
  border-radius: 8px;
  display: flex;
  z-index: 9;
}


.manual-change-detection__initial-popup p {
  margin-right: 15px; 
}


.manual-change-detection__initial-popup button {
  background: url("../images/close-popup-button.png") no-repeat;
  background-size: contain; 
  border: none;
  width: 10px; 
  height: 10px;
  margin-top: 4px;
  cursor: pointer;
}

 .path-bar {
    display: flex;
    height: 3em;
    /* background: rgb(205, 216, 189); */
    background: transparent;
}

.path-bar p {
    margin-top: auto;
    margin-bottom: auto;
}

.path-bar__title {
    font-style: normal;
    font-weight: bold;
    letter-spacing: -0.015em;
    color: #5F5F5F;
}

.back-button {
    all: unset;
    margin-left: 1%;
    z-index: -1;
}

.back {
   
    position: absolute;
    width: 33.5px;
    height: 28px;
    left: 23px;
    top: 99px;
    border: 3px transparent;


}

.back-button:hover {
    cursor: pointer;
} 
.header-container {
    display: flex;
    width: 100%;
    justify-content: space-between;
  }
  
.header-container img.create-icon {
    margin-left: 15px;
}

.btn {
    border-radius: 10px;
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 23px;
    text-align: center;
    letter-spacing: -0.015em;
    border: 0;

    height: 2.05em;
    width: 11.8em;

    cursor: pointer;
}

.btn-container {
    display: flex;
    width: 294px;
    justify-content: space-between;
}
  
.btn.other-act-button {
    background-color: rgba(210, 210, 210, 0.19);
    border: 2px solid var(--dark-surface);
    border-radius: 15px;
    width: 135px;

    color: var(--dark-surface);
    font-weight: 500;

    display: flex;
    justify-content: center;
    align-items: center;
}
  
.btn.other-act-button img {
    margin-right: 10px;
}
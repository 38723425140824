.mowing-report {
  display: flex;
  flex-direction: column;
  color: var(--dark-surface);
}

.mowing-columns {
  display: flex;
  flex-direction: row;
  position: relative;
}

.mowing-map {
  position: absolute;
  top: 40px;
  right: 0px;
}

.mowing-report-text-bolded {
  font-size: 16px;
  font-weight: 700;
  line-height: 22.4px;
  text-align: left;
}

.mowing-report-text-unbolded {
  font-size: 16px;
  font-weight: 300;
  line-height: 22.4px;
  text-align: left;
}

.mowing-report-overview {
  display: flex;
  flex-direction: column;
}

.mowing-report-project-name {
  padding: 8px 0px;
}

.mowing-report-description {
  padding: 8px 0px;
  width: 676px;
}

.mowing-report-details-title {
  padding: 8px 0px;
}

.mowing-report-details-row {
  display: flex;
  flex-direction: row;
  padding: 4px 0px;
  width: 275px;
  justify-content: space-between;
}

.mowing-event-summary {
  padding-bottom: 24px;
  display: block;
  overflow-x: auto;
  white-space: nowrap;
  border-spacing: 0px;
  max-width: 100%;
}

.mowing-event-summary::-webkit-scrollbar-thumb {
  border-style: none;
  border-radius: 15px;
  background-color: var(--dark-subtle-surface);
}

.mowing-event-summary th, td {
  padding: 12px;
  background-color: var(--light-surface) !important;
  border-left: 2px solid var(--dark-subtle-surface) !important;
}

.mowing-event-summary th:first-child, td:first-child {
  border-left: none !important;
}

.mowing-event-summary-column-header  {
  text-align: right;
}

.mowing-field-selector {
  width: 200px;
  height: 40px;
  padding: 4px;
  margin-left: 16px;
  border-style: solid;
  border-color: var(--dark-surface);
  border-width: 2px;
  border-radius: 10px;
  font-size: 16px;
  font-weight: 300;
  color: var(--dark-surface);
}

.mowing-field-selector:invalid {
  color: var(--dark-subtle-surface);
}
.recent-activity-frame{
    display: flex;
    height: auto;
    flex-direction: row;
    justify-content: space-between;
}

.recent-activity-tab{
    margin:10px;
    background-color: #f5f5f5;
    width: 350px;
    
    padding: 15px;
    border-radius:15px;

    height: 100%;
    overflow: scroll;
}

.recent-activities-collection{
    display: flex;
    flex-direction: column;
}

.recent-activity-container{
    position: relative;
    display: flex;
    justify-content: center;
    margin:10px;
    margin-block: 5px;
    padding: 5px;
    border-radius:10px;
}

.recent-activity-newlabel{
    position:absolute;
    top: 5px;
    right: 7px;
}

.recent-activity-content{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin:10px;
}
.recent-activity-content h4{
    margin-block:10px;
}
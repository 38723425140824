/* Top level styles as defined in figma (last updated Nov 26, 2021): https://www.figma.com/file/1Shh83liRkbSQaRSjwH3tW/DelayedHayScreens_Current */
/* OR commonly used text styles */



html {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

*, *:before, *:after {
  box-sizing: inherit;
}

h1 {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 100px;
  letter-spacing: -0.005em;
  margin: 0;

  color: #3D3D3D;
}

h2 {
  font-family: Roboto;
  font-style: normal;
  font-weight: 900;
  font-size: 42px;
  letter-spacing: -0.015em;
  margin: 0;

  color: #3D3D3D;
}

h3 {
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 26px;
  letter-spacing: -0.005em;
  margin: 0;

  color: #3D3D3D;
}

h4 {
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  margin: 0;

  color: #3D3D3D;
}

h5 {
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  letter-spacing: -0.015em;
  margin: 0;

  color: #B8B8B8;
}

p {
  font-family: Roboto;
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  margin: 0;

  letter-spacing: 0.005em;

  color: #3D3D3D;
}

b {
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;

  color: #3D3D3D;
}

#root {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.body {
  height: 100%;
}

.text-button {
  border: none;
  background: none;
  cursor: pointer;
}

.text-button:hover {
  transform: scale(1.1);
}

.big-primary-button {
  background: #357960;
  color: #FFFFFF;
  border: none;
  padding: 0.5rem;
  border-radius: 10px;
  cursor: pointer;
}

.page-margins {
  margin-left: 5rem;
  margin-right: 5rem;
  margin-top: 1.5rem;
}

/* Scrollbar */
::-webkit-scrollbar {
  width: 10px;
}
/* Track */
::-webkit-scrollbar-track {
  background: transparent; 
}
/* Handle */
::-webkit-scrollbar-thumb {
  background: #E9E9E9;
  border-radius: 5px;
}
/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #c4c4c4; 
}
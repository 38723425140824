.project-region__mapbox-container {
  position: relative;
  height: 100%;
  width: 100%;
}

.project-region-add {
  position: absolute;
  justify-content: center;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  margin: auto;
  z-index: 1;
}

.project-region__mapbox {
  position: absolute;
  justify-content: center;
  top: 0px;
  right: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  margin-bottom: 10px;
  /* left: -16px */
}

.project-region-add__sidebar {
  background-color: rgba(35, 55, 75, 0.9);
  color: #ffffff;
  padding: 6px 12px;
  font: 15px/24px monospace;
  z-index: 1;
  position: absolute;
  justify-content: center;
  align-items: center;
  align-self: center;
  align-content: center;
  margin: 12px;
  border-radius: 4px;
}

.openbtn {
  background: rgba(255, 255, 255);
  border: none;
  border-radius: 0.5em;
  padding: 10px;
}

.openbtn img {
  width: 70%;
}

.search-bar {
  z-index: 100;
  position: absolute;
  top: 15px;
}

.openbtn .hover-text,
.help .hover-text {
  visibility: hidden;
  width: 10em;
  background-color: #4e4e4ec7;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  opacity: 0;
  left: 0;
  top: 35px;
  transition: opacity 0.3s;
}

.openbtn:hover, .closebtn:hover {
  cursor: pointer;
}

.openbtn:hover .hover-text,
.help:hover .hover-text {
  visibility: visible;
  opacity: 1;
}

.closebtn{
  all: unset;
  font-size: 2em;
  text-align: end;
  font-weight: 100;
  margin-right: 5%;
  top: 0;
  /* margin-top: -1.8em; */
  color: #3A3A32;
  cursor: pointer;
  margin: 40px;
  background-color: #707070;
}

.closebtn.map {
  position: absolute;
  right: 10px;
  margin-top: unset;
  margin-right: unset;
}

.sidenav {
  z-index: 10;
  position: fixed;
  /* height: 100%;
  width: 20em;
  top: -2px; */
  background-color: rgb(255, 255, 255);
  /* border-radius: 0 0.5em 0.5em 0; */
  width: 0;
  overflow-x: hidden; /* Disable horizontal scroll */
  transition: 0.5s; /* 0.5 second transition effect to slide in the sidebar */
}

.sidenav p {
  text-align: start;
  margin-left: 8%;
  font-weight: bold;
  color: #3A3A32;
}

.mapbox-style-buttons {
  display: flex;
  flex-direction: column;
  width: 20%;
  padding: 10px;
}

.mapbox-style-buttons button,
.overlay-setting-buttons button {
  border: none;
  margin-right: 10px;
  padding: 0.5em 0.7em 0.5em 0.7em;
  background: rgba(210, 210, 210, 0.19);
  width: 9em;
  box-sizing: border-box;
  border-radius: 1.2em;
  border: 0.1em solid #707070;

  font-style: normal;
  font-weight: normal;
  letter-spacing: -0.015em;
  color: #3A3A32;
}

.mapbox-style-buttons button:hover,
.overlay-setting-buttons button:hover {
  background: rgba(148, 148, 148, 0.19);
  cursor: pointer;
}


.mapbox-style-buttons .toggle-button.active {
  background: #357960;
  color: #FFF;
}

.mapboxgl-popup {
  max-width: 200px;
}

.mapboxgl-popup-content {
  text-align: center;
  font-family: 'Open Sans', sans-serif;
}

.disabled-button {
  -webkit-filter: opacity(.3) drop-shadow(0 0 0 #FFF);
  filter: opacity(.3) drop-shadow(0 0 0 #FFF);
  cursor: default !important;
}

.map-topleft-panel {
  z-index: 100;
  position: absolute;
  top: 1%;
  left: 1%;
  background: rgba(255, 255, 255);
  border: none;
  border-radius: 0.5em;
  padding: 10px;
}

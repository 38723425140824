.expandable-frame-container{
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.expandable-content{
  transition: height 0.5s ease-in-out;
  overflow: hidden;
}

.expand-trigger{
  display:flex;
  flex-direction:column;
  align-items: center;
  cursor: pointer;
}

.expand-trigger:hover{
  background-color:#B5B5B5;
}

.expand-trigger-caption{
  color:#B5B5B5
}
  .map-box-container {
    width: 900px;
    height: 480px;
    flex-shrink: 0;
    padding: 10px;

  }

  .map-property-and-buttons-container {

    flex-direction: column;
  }

  .map-and-property-container {
    display: flex;
    flex-direction: row;

  }

  .button-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin: auto;
    margin-top: 20px;
  }

  .create-new-button-container {
    margin: auto;
    margin-top: 20px;
    margin-right: 10px;
  }



  .property-container {
    flex-direction: column;
    height: 480px;
    width: 700px;
    overflow-y: auto;

  }
 
  .property-page__header {
    letter-spacing: -0.3px;
    margin-right: 500px;
    margin-bottom: 100px;
  }

  .property-page__header h3 {
    font-size: 20px;
    color: var(--primary, #357960);
  }

  .property-date-created__page__header {
    line-height: 140%; /* 22.4px */
    letter-spacing: 0.08px;
  }

  .property-date-created__page__header h3 {
    color: #4D4D4D;
    font-size: 16px;
  }

  .property-date__page__header {
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }

  .property-date__page__header h3 {
    color: #4D4D4D;
    font-size: 16px;

  }

  .popup-container {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1000;
  }
  
  .popup-content {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
  }
  
  .popup-inner {
    background-color: white;
    border: 1px solid #ccc;
    border-radius: 10px;
    padding: 20px;
    text-align: center;
  }
  
  .popup-inner h2 {
    font-size: 1.5em;
    text-align: left;
    font-weight: bold;
    margin-bottom: 20px;
  }
  
  .popup-inner p {
    font-family: inherit;
    font-size: 1.3em;
    margin-bottom: 20px;
  }
  
  .popup-buttons button {
    margin-top: 10px;
    display: inline-block;
    margin-right: 10px;
    line-height: 2; 
  }
  
  .popup-buttons button {
    display: inline-block;
    margin-right: 10px;
  }

  .note-container {
    color: var(--dark-surface, #4D4D4D);
    text-align: center;
    font-family: Roboto;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.027px;
    margin-top: 30px;
  }
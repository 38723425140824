.gedi-view-body {
  display: flex;
  flex-direction: column;
  margin-left: 5%;
  margin-right: 5%;
   
}

.gedi-view-form-col {
  display: flex;
  flex-direction: column;
  margin-bottom: 4em;
  width: 100%;
   
}

.gedi-view-title {
  display: flex;
   
}


.gedi-view-title img {
  width: 2em;
  position: relative;
  align-self: center;
}

.gedi-view-title div{
  width: 400px;
  margin-left: 10px;
  background-color: rgb(251, 251, 251);
  border: 1px solid black ;
  font-weight: bold;
  border-radius: 5px;
  padding: 7px;
  z-index: 1;
  align-self: center;
}

.gedi-view-title h1 {
  padding: 20px 10px;
  font-style: normal;
  width: 10em;
  font-weight: 700;
  letter-spacing: -0.015em;
  color: #3D3D3D;
  text-align: left;
  font-size: 2em;
   
}

.gedi-view-page-layout {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
   
}

.gedi-view-section-1 {
  display: flex;
  flex-direction: column;
  width: 100%;
   
}

.gedi-view-section-2 {
  display: flex;
  flex-direction: column;
  width: 100%;
   
}

.gedi-warning-text2 {
  color: red;
  text-align: left;
  width: 100%; 
}

.gedi-view-form-body-1 {
  font-style: normal;
  text-align: start;
  font-weight: bold;
  color: #3A3A32;
  letter-spacing: -0.015em;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
   
}

.gedi-view-form-body-2 {
  font-style: normal;
  text-align: start;
  font-weight: bold;
  color: #3A3A32;
  letter-spacing: -0.015em;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-top: 5%;
   
}

.gedi-warning-text {
  color: red;
  width: 40%;
}

.gedi-view-subtitle {
  margin-bottom: 2%;
   
}

.gedi-view-input-block {
  height: auto;
  overflow: auto;
  margin-bottom: 1em;
  display: flex;
  justify-content: space-between;
  width: 50%;
  overflow: hidden;   
}

.gedi-view-mapbox {
  height: 70%;
  display: flex;
  align-items: center;
}

.gedi-view-interpolation-section {
  display: flex;
  flex-direction: row;
  width: 100%;
   
  font-weight: bold;
  color: #3A3A32;
  height: 20%;
}

.gedi-view-interpolation-section img {
  width: 2em;
  position: relative;
  align-self: center;
}

.gedi-view-interpolation-popup {
  height: auto;
  overflow: auto;
  display: flex;
  outline-color: #357960;
  width: 300px;
  background-color: white;
  border: 1px solid black ;
  border-radius: 10px;
  padding: 7px;
  margin-left: 20px;
  z-index: 1;
  align-self: center;
}

.gedi-view-interpolation-result {
  height: auto;
  overflow: auto;
  display: flex;
  overflow: hidden;
  flex-direction: column;
  width: 40%;
  box-shadow: #3A3A32;
  align-self: center;
}

.gedi-view-form label {
  display: inline-block;
  vertical-align: top;
   
}


.gedi-view-warning-text {
  color: red;
  width: 40%;
   
}

.gedi-view-button-section {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 5%;
   
}

.gedi-view-dropdown{
  text-align: center;
  position: relative;
  background: #E5F4E4;
  width: 12em;
  border-radius: 0.4em;
  border: none;
  font-weight: 700;
  color: #357960;
  padding: 0.5em;
  margin-right: 10px;
  float: left;
}

.gedi-view-dropdown:hover{
  background: #798777;
  cursor: pointer;
  color: #E5E4CC;
}

.gedi-view-dropdown:disabled{
    text-align: center;
    position: relative;
    background: #818280;
    width: 12em;
    border-radius: 0.4em;
    border: none;
    font-weight: 700;
    color: #40403d;
    padding: 0.5em;
    margin-right: 10px;
    float: left;
}

.gedi-view-button {
  text-align: center;
  position: relative;
  background: #357960;
  width: 12em;
  border-radius: 0.4em;
  border: none;
  font-weight: 700;
  color: #fff;
  padding: 0.5em;
  margin-right: 3%;
  float: left;
   
}

.gedi-view-button:hover {
  background: #798777;
  cursor: pointer;
  color: #E5E4CC;
   
}

.gedi-view-button-disabled {
  text-align: center;
  position: relative;
  background: #818280;
  width: 12em;
  border-radius: 0.4em;
  border: none;
  font-weight: 700;
  color: #40403d;
  padding: 0.5em;
  margin-right: 10px;
  float: left;
}

.property-draw-body {
  position: relative;
  /* margin-left: 5rem;
  margin-right: 5rem;
  margin-top: 4rem; */
}

.property-draw-body > h3 {
  text-align: left;
}

.property-draw-body.map-container {
  width: 100%;
}

/* .property-draw-form-body {
    display: grid;
    grid-template-rows: 1fr;
    grid-template-columns: repeat(4, 1fr);
    column-gap: 2em;
  } */

.property-draw-text-input {
  border: 0.4px solid #000000;
  box-sizing: border-box;
  height: 2em;

  font-family: Roboto;
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 140%;
  letter-spacing: 0.005em;

  color: var(--dark-surface);
}

.property-draw-mapbox-container {
  width: 90%;
  min-height: 35rem;
  max-height: 35rem;
  margin-top: 1rem;

  margin-left: auto;
  margin-right: auto;
}

.property-draw-button-container {
  margin-top: 1em;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}

.property-draw-submit-btn {
  background-color: var(--light-primary);
  color: var(--primary);

  border-radius: 10px;
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 23px;
  text-align: center;
  letter-spacing: -0.015em;
  border: 0;

  height: 2.05em;
  width: 11.8em;

  cursor: pointer;
}

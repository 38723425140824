.table-body {
  width: 213px;
  height: 506px;
  margin-left: 32px;
  margin-top: 1em;

  background: #E9E9E9;
  border-radius: 5px;
}

.list-link {
  width: 100%;
}

.tab-link {
  margin: 0;
  background: #BAC7A7;
  border: none;
  border-radius: 0.4em;
  border-bottom: 2px solid #D4D4D4;
  width: 100%;
  height: 68px;
  text-align: start;
  font-weight: 900;
  background: #E9E9E9;
  color: #3D3D3D;
  padding: 0;
  padding-left: 1em;
}

.tab-link:hover {
  color: #E9E9E9;
  background: #3D3D3D;
  cursor: pointer;
}

.current-tab {
  color: #E9E9E9;
  background: #3D3D3D;
}


.progress_stage_circle{
  position:absolute;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  box-sizing:border-box;
  display: flex;
  flex-flow: column;
  justify-content:center;
  align-items: center;

  margin-left: 90%;
  background: #c4c4c4;
}

.progress_stage_description{
  position: absolute;
  width: auto;
  white-space: nowrap;
  top: 90%;
  font-weight: bold;
  font-size: 16px;
  letter-spacing: 0.02em;
  color: #3A3A3A;
}

.progress_bar_filler{
  height: 100%;
  border-radius: inherit;
  display: flex;
  align-items: center;
  
}

.progress_bar_container{
  position: relative;
  height: 20px;
  width: 100%;
  background-color: #c4c4c4;
  border-radius: 50px;
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: flex-start;
}

.full-progress-bar-container{
  display: flex;
  flex-flow: row-reverse;
  width: 50em;
  margin-block: 2.5em;
  margin-bottom: 4.5em;
}

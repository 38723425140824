.link {
  text-decoration: none;
  font-size: 30px;
  color: var(--dark-subtle-surface);
 }
 
 
 .selected-link {
  text-decoration: none;
  font-size: 30px;
  color: var(--dark-surface);
 }
 
 
 .report-chart-delta {
  color: var(--dark-subtle-surface);
  display: flex;
  align-items: center;
  font-size: 24px;
  margin-left: 5px;
  text-decoration: none;
 }
 
 
 .delta-link {
  margin-left: 8px;
 }
 
 
 .chart-title-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 40px;
  height: 12px;
 }
 
 
 
 
 .report-chart-link__page__header {
  margin-bottom: 30px;
  text-align: left;
 }
 
 
 .report-chart-link__page__header h3 {
  font-size: 30px;
  color: var(--dark-surface);
 }
 
 
 .report-chart-link__subpage__header {
  text-align: center;
 }
 
 
 .report-chart-link__subpage__header h3 {
  font-size: 26px;
  color: var(--dark-surface);
 }
 
 
 .report-chart-link__page {
  text-align: left;
  margin: 1em 0 1em 5rem;
 }
 
 
 .chart-container {
  text-align: center;
  margin-top: 10px;
  background-color: transparent;
 }
 
 
 .chart-heading {
  font-size: 24px;
  color: var(--dark-surface);
  margin: 0;
 }
 
 
 .centered-chart-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 500px;
  width: 900px;
  margin: 10px auto 0;
  background-color: transparent;
 }
 
 
 .chart-description {
  margin-top: 30px;
  margin-left: 50px;
  text-align: center;
  font-size: 16px;
  color: var(--dark-subtle-surface);
 }
 

 .ndvi-chart-csv-download-button {
  height: 40px;
  width: 40px;
  padding: 8px;
  gap: 8px;
  border-radius: 5px;
  background: #FFF;
  cursor: pointer;
  /* margin-left: auto; */
  border: none;
 }
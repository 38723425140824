.frame {
  display: flex;
  flex-direction: row;
  height: 100%;
  width: 100%;
}

.image {
  object-fit: cover;
  /* filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 25)); */
  filter: brightness(70%);
  height: 100%;
  width: 1100px;
}

.logo-column {
  position: relative;
  display: inline;
}

.login-column {
  min-width: 600px;
  height: 100vh;
  width: 100%;
}

.korotu-bg {
  filter: brightness(50%);
  height: 100%;
}

.korotu-text {
  position: absolute;
  top: 35%;
  left: 10%;
  width: 80%;
}

.login-box {
  display: flex;
  flex-direction: column;
  width: 420px;
  max-height: 100%;
  background: #357960;
  border: 1px solid #698474;
  box-sizing: border-box;
  border-radius: 24px;
  margin: auto;
  margin-top: 19vh;
  padding: 2em;
}

.login-form {
  display: flex;
  flex-direction: column;
  margin: auto;
  margin-top: 1em;
  margin-bottom: 1em;
}

.signin-text {
  text-align: center;
  font-family: Roboto;
  font-style: normal;
  font-weight: 450;
  font-size: 26px;
  line-height: 29px;

  color: #FFFFFF;
  margin-top: 2px;


  
}

.input-text {
  font-weight: 300;
  font-family: Roboto;
  font-style: normal;
  font-size: 22px;
  line-height: 20px;
  text-align: left;
  margin-top: 10px;


  color: #F0F0F0;
}

.input-field {
  position: relative;
  font-size: 22px;
  width: 100%;
  height: 1.8em;
  left: 0;
  top: 0px;

  background: #FFFFFF;
  border: 1px solid #357960;
  box-sizing: border-box;
  border-radius: 10px;
}

.submit-btn:hover {
  background: #ffffff;
  opacity: 0.7;
  cursor: pointer;
}

.submit-btn {
  position: relative;
  margin-left: auto;
  margin-right: auto;
  margin-top: 30px;
  border: 0px;

  background: #E5F4E4;
  box-sizing: border-box;
  border-radius: 10px;
  height: 40px;
  width: 236px;
  left: 0;

  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 23px;
  text-align: center;
  letter-spacing: -0.015em;

  cursor: pointer;
  color: #357960;
}

.forgot-btn {
  position: relative;
  margin-left: auto;
  margin-right: auto;
  margin-top: 10px;
  border: 0px;

  background: #E5F4E4;
  box-sizing: border-box;
  border-radius: 10px;
  height: 40px;
  width: 236px;
  left: 0;

  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 23px;
  text-align: center;
  letter-spacing: -0.015em;

  cursor: pointer;
  color: #357960;
  
}

.forgot-btn:hover {
  background: #ffffff;
  opacity: 0.7;
  cursor: pointer;
}

hr {
  margin: 2em;
}

.signup-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.signup-container > p {
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;

  color: #FFFFFF;
}

.signup-btn {
  position: relative;
  margin-left: auto;
  margin-right: auto;

  background: #E5F4E4;
  box-sizing: border-box;
  border-radius: 10px;
  height: 41px;
  width: 142px;
  left: 0;
  border: 0px;

  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 23px;
  text-align: center;
  letter-spacing: -0.015em;

  cursor: pointer;
  color: #357960;
}

.signup-btn:hover {
  background: #ffffff;
  opacity: 0.7;
  cursor: pointer;
}

.name-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.korotu-copyright{
/* position: absolute; */
width: 163px;
height: 52px;
justify-content: center;
flex-direction: column;
margin: auto;

/* margin-left: 370px;
margin-top: 130px; */

/* subheading (old) */

font-family: 'Roboto';
font-style: normal;
font-weight: 500;
font-size: 14px;
line-height: 16px;
display: flex;
align-items: center;
letter-spacing: -0.015em;

/* dark surface */

color: #4D4D4D;
}

.warning-text {
  color: red;
  font-family: Roboto;
  font-style: normal;
  font-weight: 900;
  font-size: 14px;
  line-height: 20px;
}
.links {
  display: flex;
  flex-direction: column;
  margin-left: 0em;
  text-decoration: none;
}

.nav-link {
  
flex-direction: row;
align-items: center;
padding: 16px;

position: static;
width: 1198px;
height: 80px;
left: 0px;
top: 504px;

background: #E5F4E4;
border-color: transparent;

/* Inside auto layout */

flex: none;
order: 6;
align-self: stretch;
flex-grow: 0;
margin: 5px 38px;

font-family: Roboto;
font-style: normal;
font-weight: 500;
font-size: 20px;
line-height: 23px;
letter-spacing: -0.015em;
text-align: left;

/* primary */

color: #357960;
}

.nav-link:hover {
  cursor: pointer;
  border-color: #357960;
  color: #357960;
  /* font-size: 24px; */
  font-weight: 700;
  border-radius: 7px;
  cursor: pointer;
  border-width: 5px
}

.forward {
  height: 1.3em;
  width: 2.2em;
  float: right;
}


.track-status_note-display-tab{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-evenly;

  width: 50em;
  min-height: 200px;
  padding: 1.5em;

  background: #e7e7e7;
  border-radius: 30px;
}
.track-status-tab{
  display: flex;
  flex-direction: column;
}

.track-status-note{
  display: flex;
  flex-wrap: nowrap;
  align-items: flex-start;

  font-size: 20px;
  line-height: 1.5em;
  letter-spacing: -0.015em;
  color: #3A3A32;
}

.track-status-note-content{
  margin-left: 0.5em;
  text-align: start;
  word-wrap: break-word;
}

.track-bottom-msg{
  margin-block: 2em;
  font-weight: bold;
  font-size: 15px;
  line-height: 21px;
  letter-spacing: 0.02em;
}

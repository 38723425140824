.project-observation-title {
  display: flex;
flex-direction: row;
align-items: center;
/* padding: 8px; */

position: static;
/* width: 1312px; */
height: 36px;
left: 8px;
top: 8px;


/* Inside auto layout */

flex: none;
order: 0;
align-self: stretch;
flex-grow: 0;
/* margin: 8px 0px; */
}

.project-observation-title-text {

  height: 19px;


  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;

  display: flex;
  color: var(--dark-surface);

}

.project-observation-title-text .sort-icon {
  position: static;
  width: 21px;
  height: 21px;

  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 0px 15px;
}

.project-details.project-page {
  width: 100%;
  /* overflow-y: auto; */
  min-height: 100vh;
  max-height: 100vh;
  margin: unset;

}

.project-details::-webkit-scrollbar {
  width: 12px;
}

.project-details::-webkit-scrollbar-track {
  background: transparent; 
}

.project-details::-webkit-scrollbar-thumb {
  border-radius: 15px;
  background: #B5B5B5;
}

.project-description {
  text-align: left;
  /* margin-left: 22px; */
}

.select-project-button {
    background: #BAC7A7;
    border-radius: 0.5em;
    border: none;
    font-weight: 700;
    color: #3A3A32;
    padding: 0.37em;
    position: absolute;
    bottom: 45px;
    padding-right: 1em;
    z-index: 10;
    left: 100px;
    padding-left: 1em;
}

.project-characteristics {
  width: 100%;
  display: flex;
  /* flex-wrap: wrap; */
  flex-direction: row;
  align-content: flex-start;
  justify-content: space-between;
  /* overflow-y: auto; */
 
}

.project-characteristic-child {
  /* flex: 1 0 calc(20% - 20px);
  max-width: calc(20% - 20px); */
  height: 5em;
  margin: 10px 0;
}

.project-characteristic-child.map{
  width: 336px;
  height: unset;
  /* position: relative;
  z-index: -1; */
}
.project-characteristic-info {
  font-family: Roboto;
  font-style: normal;
  font-weight: 200;
  font-size: 48px;
  line-height: 56px;
  letter-spacing: -0.015em;

  /* dark surface */

  color: #4D4D4D;
}

.project-landowners-heading {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 10px;

}

.image-gallery__title-share{
  display: flex;
flex-direction: row;
align-items: center;
padding: 0px 17px;

position: static;
width: 127px;
height: 36px;
left: 8px;
top: 8px;

background: rgba(210, 210, 210, 0.19);
/* dark surface */

border: 2px solid #4D4D4D;
box-sizing: border-box;
border-radius: 15px;

/* Inside auto layout */

flex: none;
order: 0;
flex-grow: 0;
margin: 0px 0px;
cursor: pointer;
justify-content: space-around;
  align-items: center;
}
.image-gallery__title-share:hover{
    background-color: #357960;
    color: white;
}


.project-import{
  display: flex;
flex-direction: row;
align-items: center;
padding: 0px 17px;

position: relative;
width: 127px;
height: 36px;
/* left: 96px; */
left: 75px;
top: 0px;

background: rgba(210, 210, 210, 0.19);
/* dark surface */

border: 2px solid #4D4D4D;
box-sizing: border-box;
border-radius: 15px;

/* Inside auto layout */

flex: none;
order: 0;
flex-grow: 0;
margin: 0px 0px;
cursor: pointer;
justify-content: space-around;
  align-items: center;
}
.project-import:hover{
    background-color: #357960;
    color: white;
}

.create-button {
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
padding: 0px 0px;

position: static;
width: 240px;
height: 40px;
left: 143px;
top: -2px;

/* primary */

background: #357960;
border-radius: 10px;

/* Inside auto layout */

flex: none;
order: 2;
flex-grow: 0;
margin: 0px 8px;
font-family: Roboto;
font-style: normal;
font-weight: 500;
font-size: 20px;
line-height: 23px;
text-align: center;
letter-spacing: -0.015em;

/* light surface */

color: #FFFFFF;
cursor:pointer;
}

#regionlist {
  margin-top: 10px;
  padding: 10px;
}

.popup-box {
    width: 420px;
    height: 230px;
    border-radius: 10px;
    background: #FFF;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    padding: 36px;
}

.popup-box p {
    margin-bottom: 8px;
    font-size: 18px;
    margin-top: 18px;
}

.popup-heading {
    text-align: left;
    font-size: 26px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: -0.13px;
}

.popup-input {
    width: 348px;
    height: 32px;
    flex-shrink: 0;
    margin-top: 20px;
    border-radius: 10px;
    border: 1px solid var(--primary, #357960);
    background: var(--light-surface, #FFF);
}

.character-info {
    color: var(--dark-subtle-surface, #B5B5B5);
    text-align: right;
    margin-top: 5px;
}

.character-info-warning {
    color: #DA4167;
    text-align: right;
    margin-top: 5px;
}

.btn-group {
    width: 348px;
    margin-top: 10px;
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: space-between;
}

.rename-btn {
    display: flex;
    width: 168px;
    height: 40px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    border-radius: 10px;
    background: var(--primary, #357960);
    border: none;
    cursor: pointer;

    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: -0.3px;
    color: var(--light-surface, #FFF);
    text-align: center;
}

.delete-btn {
    display: flex;
    width: 168px;
    height: 40px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    border-radius: 10px;
    background: var(--primary, #357960);
    border: none;
    cursor: pointer;

    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: -0.3px;
    color: var(--light-surface, #FFF);
    text-align: center;
}

.cancel-btn {
    display: flex;
    width: 168px;
    height: 40px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    border-radius: 10px;
    background: var(--light-primary, #E5F4E4);
    border: none;
    cursor: pointer;

    color: #357960;
    text-align: center;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: -0.3px;
}
.mowing-form {
  display: flex;
  flex-direction: column;
  color: var(--dark-surface);
}

.mowing-section-header {
  display: flex;
  flex-direction: row;
  position: relative;
  align-items: center;
  margin: 32px 0px;
  width: 100%;
}

.mowing-section-header img {
  margin: 0px 16px;
  width: 24px;
  height: 24px;
}

.mowing-section-header button {
  margin: 0px 16px;
}

.mowing-section-header:first-child {
  margin-top: 0px;
}

.mowing-form-project {
  display: flex;
  flex-direction: row;
  padding: 8px 0px 16px;
}

.mowing-form-project-label {
  font-size: 16px;
  font-weight: 700;
}

.mowing-form-project-name {
  font-size: 16px;
  font-weight: 300;
  margin-left: 16px;
}

.mowing-form-input {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: fit-content;
  padding: 4px 0px;
}

.mowing-form-input-label {
  font-size: 18px;
  font-weight: 400;
}

.mowing-form-input-field {
  margin: 6px 0px;
  padding: 0px 7px;
  width: 348px;
  height: 32px;
  border-style: solid;
  border-width: 1px;
  border-radius: 10px;
  border-color: var(--primary);
  font-size: 16px;
  font-weight: 300;
}

input.mowing-form-input-field {
  padding: 12px 10px;
}

.mowing-form-input-field::placeholder {
  color: var(--dark-subtle-surface);
}

.mowing-form-input-field-empty {
  margin: 6px 0px;
  padding: 12px 10px;
  width: 348px;
  height: 32px;
  border-style: solid;
  border-width: 1px;
  border-radius: 10px;
  border-color: var(--primary);
  font-size: 16px;
  font-weight: 300;
  color: var(--dark-subtle-surface);
}

:disabled {
  background-color: var(--light-subtle-surface);
  border-style: none;
  color: var(--dark-surface);
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
}

select::-ms-expand {
  display: none;
}

.mowing-form-input-limit {
  margin-left: 90%;
  font-size: 16px;
  font-weight: 300;
  color: var(--dark-subtle-surface);
}

.mowing-form-popup-notice {
  display: flex;
  flex-direction: row;
  position: absolute;
  inset: 0px 0px 120px 0px;
  margin: auto;
  padding: 5px 10px;
  z-index: 2;
  width: fit-content;
  height: fit-content;
  background-color: var(--light-surface);
  border-style: solid;
  border-width: 0.5px;
  border-radius: 10px;
  border-color: var(--dark-surface);
  align-self: center;
  align-items: flex-start;
}

.mowing-form-popup-notice-text {
  max-width: 450px;
  font-size: 16px;
  font-weight: 300;
  line-height: 22.4px;
  text-align: left;
  align-self: center;
}

.mowing-form-popup-notice-button {
  margin: 5px 0px 5px 10px;
  width: 10px;
  height: 10px;
}

.mowing-form-popup-prompt {
  display: flex;
  flex-direction: column;
  position: fixed;
  z-index: 2;
  inset: 0px;
  margin: auto;
  padding: 10px 20px;
  max-height: max-content;
  max-width: max-content;
  align-items: center;
  background-color: var(--light-surface);
  border-style: none;
  border-radius: 10px;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}

.mowing-form-popup-prompt-text {
  max-width: 387px;
}

.mowing-form-popup-prompt-title {
  margin: 10px 0px;
  font-size: 26px;
  font-weight: 700;
  text-align: left;
}

.mowing-form-popup-prompt-details {
  margin: 20px 0px;
  font-size: 20px;
  font-weight: 400;
  text-align: left;
}

.mowing-form-popup-prompt-buttons {
  display: flex;
  flex-direction: row;
}

.mowing-form-popup-prompt-dark-button {
  margin: 12px;
  width: 185px;
  height: 40px;
  background-color: var(--primary);
  border-style: none;
  border-radius: 10px;
  font-size: 20px;
  font-weight: 500;
  color: var(--light-surface);
}

.mowing-form-popup-prompt-light-button {
  margin: 12px;
  width: 185px;
  height: 40px;
  background-color: var(--light-primary);
  border-style: none;
  border-radius: 10px;
  font-size: 20px;
  font-weight: 500;
  color: var(--primary);
}

.mowing-form-buttons {
  display: flex;
  flex-direction: row;
  justify-content: center;
  position: relative;
  z-index: 1;
}

.mowing-form-dark-button {
  display: flex;
  flex-direction: row;
  width: 255px;
  height: 45px;
  margin: 20px 16px;
  align-items: center;
  justify-content: center;
  background-color: var(--primary);
  border-style: none;
  border-radius: 10px;
  font-size: 20px;
  font-weight: 500;
  color: var(--light-surface);
}

.mowing-form-dark-button img {
  margin-left: 16px;
  width: 24px;
  height: 24px;
  filter: brightness(0) invert(1);
}

.mowing-form-dark-button-disabled {
  width: 255px;
  height: 45px;
  margin: 20px 16px;
  background-color: var(--light-subtle-surface);
  border-style: none;
  border-radius: 10px;
  font-size: 20px;
  font-weight: 500;
  color: var(--dark-subtle-surface);
}

.mowing-form-light-button {
  width: 255px;
  height: 45px;
  margin: 20px 16px;
  background-color: var(--light-primary);
  border-style: none;
  border-radius: 10px;
  font-size: 20px;
  font-weight: 500;
  color: var(--primary);
}

.searching-animation {
  display: flexbox;
  flex-direction: row;
  margin-left: auto;
  margin-right: auto;
}

.character {
  position: relative;
  opacity: 0;
  animation: move-text 1s infinite;
}

@keyframes move-text {
  0% { bottom: -0.15em; opacity: 1; }
  50% { bottom: 0.15em; }
  100% { bottom: 0; opacity: 1; }
}
.detail-display-card {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 10px;
  margin-block: 5px;
  padding: 1.5rem;
  border-radius: 10px;
  background: var(--light-primary);
}

.detail-display-card h4 {
  margin: 10px;
}

.detail-display-card p {
  margin: 10px;
}

.observations-imgs {
  display: flex;
  width: 100%;
  overflow-x: scroll;
}
.observation-img {
  object-fit: cover;
  margin: 15px;
  width: 400px;
  height: 400px;
}

.observation-btns {
  display: flex;
}
.observation-btns button {
  margin: 10px;
}

.detail-display-card-timestamp {
  position: absolute;
  top: 20px;
  right: 20px;
}

.detail-display-data {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.display-card-back-btn {
  position: absolute;
  top: 2rem;
  left: 5px;
  max-width: 1rem;
  height: auto;

  cursor: pointer;
}

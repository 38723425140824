.landowner-page-name {
  text-align: left;
  margin: 1em 0 1em 105px;
}

.landowner-page-info {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

}
.landowner-header-icon-container {
  display: flex;
  height: 25px;
  align-items: center;
  margin-top: 10px;
}

.landowner-header-icon-container .star {
  height: 20px;
}

.landowner-header-icon-container .user-type {
  height: 100%;
  margin-right: 10px;
}

.landowner-metadata-container {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.landowner-page-info p {
  text-align: left;
}

.landowner-program-name {
  text-align: left;
  margin: 20px 0 20px 0;
}

.landowner-property-info {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.landowner-page-property-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 41em;
}

.landowner-page-property-header img {
  width: 15px;
}

.landowner-page-property-body {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 1.5em;
}

.landowner-page-char-info {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  width: 100%;
  margin-left: 5em;
}

.landowner-page-char-info > :not(:first-child) {
  margin-left: 2.5em;
}

.landowner-page-char-info-child {
  text-align: center;
}

.landowner-page-char-info-child h4 {
  font-weight: normal;
}

.landowner-page-char-info-child p {
  font-family: Roboto;
  font-style: normal;
  font-weight: 200;
  font-size: 36px;
  line-height: 42px;
  text-align: center;
  letter-spacing: -0.015em;

  /* dark surface */

  color: #4d4d4d;
}

.landowner-page-mapbox-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 8px;

  width: 320px;
}
.landowner-subheader-container {
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-top: 20px;
}

.landowner-subheader-container .recent-activity-frame {
  width: 100%;
}

.landowner-page-properties-container {
  margin: 20px 0rem 0rem 0rem;
}

/* .landowner-page-properties-container .observations-gallery ._0.selection-card {
  background-color: #E5F4E4;
}

.landowner-page-properties-container .observations-gallery ._0.selection-card:hover {
  background-color: #E5F4E4;
}

.landowner-page-properties-container
  ._0.selection-card
  .selection-card-content
  h4,
.landowner-page-properties-container
  ._0.selection-card
  .selection-card-content
  p {
  color: black;
} */

.landowner-page-properties-container .selection-card:nth-child(odd) {
  background-color: #E5F4E4;
}

.landowner-page-properties-container .selection-card {
  width: 12rem;
  height: 16.5rem;
  padding-top: 40px;
}

.landowner-page.sort-icon {
  width: 15px;
  position: static;
  margin: unset;
  height: 100%;
}

.landowner-page-properties-header {
  display: flex;
  width: 10em;
  flex: row;
  justify-content: space-around;
  align-items: center;
  margin-left: -7px;
}

.landowner-share {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px 17px;

  position: relative;
  width: 127px;
  height: 36px;
  left: 1082px;
  top: -68px;

  background: rgba(210, 210, 210, 0.19);
  /* dark surface */

  border: 2px solid #4d4d4d;
  box-sizing: border-box;
  border-radius: 15px;

  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 0px 0px;
  cursor: pointer;
  justify-content: space-around;
  align-items: center;
}
.landowner-share:hover {
  background-color: #357960;
  color: white;
}

.landowner-import {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px 17px;

  position: relative;
  width: 127px;
  height: 36px;
  left: 921px;
  top: -32px;

  background: rgba(210, 210, 210, 0.19);
  /* dark surface */

  border: 2px solid #4d4d4d;
  box-sizing: border-box;
  border-radius: 15px;

  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 0px 0px;
  cursor: pointer;
  justify-content: space-around;
  align-items: center;
}
.landowner-import:hover {
  background-color: #357960;
  color: white;
}

.landowner-info-name {
  position: static;
  width: 168px;
  height: 19px;
  /* heading 4 */

  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  /* identical to box height */

  /* dark surface */

  color: #4d4d4d;

  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 0;
}

.landowner-info {
  position: static;
  width: 170px;

  /* paragraph */

  font-family: Roboto;
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 140%;
  /* or 22px */

  letter-spacing: 0.005em;

  color: #3d3d3d;

  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 8px 0;
  text-align: left;
}

.landowner-property-details-container {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.landowner-property-stats {
  width: 450px;
  height: 300px;
  display: flex;
  flex-direction: column;
  color: var(--dark-surface);
  justify-content: space-around;
  margin-bottom: 75px;
}

.landowner-property-stats-container {
  display: flex;
  flex-direction: column;
  height: 70%;
  justify-content: space-evenly;
}

.landowner-property-details-container h2 {
  font-weight: 700;
  font-size: 30px;
}

.landowner-property-details-container span {
  font-weight: 300;
}

.landowner-property-card-container {
  width: 90%;
}

.landowner-property-small-stats,
.landowner-property-large-stats {
  display: flex;
  justify-content: space-evenly;
}

.landowner-property-large-stats span {
  font-size: 36px;
  font-weight: 100;
}

.landowner-property-large-stats div {
  display: flex;
  flex-direction: column;
}

.landowner-property-detail-action-container {
  display: flex;
  justify-content: space-between;
}

.landowner-property-details-map-container {
  margin-top: 8.75rem;
  align-self: normal;
}

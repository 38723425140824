@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700&display=swap');


html {
  scroll-padding-top: 10rem;
}

.nav-bar {
  display: flex;
flex-direction: row;
justify-content: space-between;
align-items: center;


width: 100%;
position: absolute;
height: 70px;
left: 0%;
right: 0%;
top: 0px;
/* primary */

background: #357960;
}

.nav-links {

  display: flex;
flex-direction: row;
gap: 10px;

align-items: center;
padding: 0px 8px 0px 0px;

position: static;
width: 330px;
height: 56px;
right: 0px;
top: 4px;


/* Inside auto layout */

flex: none;
order: 1;
flex-grow: 0;
margin: 0px 0px;
}
/* 
 .nav-links button {

   width: 150px;
  margin-left: 50em;
  margin-top: 0.5em;
  font-family: 'Roboto', sans-serif;
  font-weight: 500;
  color: rgb(0, 0, 0);
  border: 0.1em solid rgb(0, 0, 0);
  background-color: transparent;
  border-radius: 20px;
  padding: 0.5em 0.7em 0.5em ; 
} */

.ppframe {
  display: flex;
flex-direction: column;
align-items: flex-start;
padding: 8px;

position: static;

height: 48px;
left: 0px;
top: 4px;


/* Inside auto layout */

flex: none;
order: 0;
flex-grow: 0;
margin: 0px 16px;
}

.ppbox {
  position: static;
height: 32px;
left: 8px;
top: 8px;

border-radius: 20px;

/* Inside auto layout */

flex: none;
order: 0;
flex-grow: 0;
margin: 0px 0px;
}
.ppbuttonframe {
  position: static;
width: 270px;
height: 32px;
left: 8px;
top: 8px;

border-radius: 20px;

/* Inside auto layout */

flex: none;
order: 0;
flex-grow: 0;
margin: 10px 0px;
}

.paymentbutton {
  
  left: 0%;
  right: 50%;
  top: 0%;
  bottom: 0%;
  width: 135px;
  height: 32px;
  left: 135px;
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 23px;
  text-align: center;
  letter-spacing: -0.015em;
  border-radius: 21px;
  /* border-color: #ffffff00; */
  border-bottom-right-radius: 0px;
  border-top-right-radius: 0px;
  background: transparent;
  cursor: pointer;
  color: #ffffff;
  border-color: white;
  
}


.projectbutton {

  left: 50%;
  right: 0%;
  top: 0%;
  bottom: 0%;
  width: 135px;
  height: 32px;
  left: 135px;
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 23px;
  text-align: center;
  letter-spacing: -0.015em;
  border-radius: 21px;
  /* border-color: #ffffff00; */
  border-color: white;
  background: transparent;
  color: white;

}

.bell{
  width: 30px;
  margin-top: 5px;
}


.nav-links button:hover {
  color: rgb(255, 255, 255);
  background-color: rgba(124, 124, 124, 0.247);
  cursor: pointer;
}

.logo img {
  float:left;
  position: absolute;
width: 180px;
height: auto;
left: 0px;
top: calc(50% - 48px/2);
}



.bellframe {
  /* Frame 181 */


/* Auto layout */

display: flex;
flex-direction: row;
align-items: flex-start;
padding: 8px;

position: static;
width: 40px;
height: 40px;
left: 302px;
top: 8px;


/* Inside auto layout */

flex: none;
order: 1;
flex-grow: 0;
margin: 0px 0px;
}
.bell-icon {
  position: static;
width: 24px;
height: 24px;
left: 8px;
top: 8px;


/* Inside auto layout */

flex: none;
order: 0;
flex-grow: 0;
margin: 0px 10px;
}

.mailbox {

/* Auto layout */

display: flex;
flex-direction: row;
align-items: flex-start;
padding: 8px;

position: static;
width: 40px;
height: 40px;
left: 358px;
top: 8px;


/* Inside auto layout */

flex: none;
order: 2;
flex-grow: 0;
margin: 0px 0px;
}
.mail-icon {
  position: static;
width: 24px;
height: 24px;
left: 8px;
top: 8px;


/* Inside auto layout */

flex: none;
order: 0;
flex-grow: 0;
margin: 0px 10px;
  

}

/*.home, .signout, .support, .profile {
  width: 1em;
  height: 1em;
  float: left;
}*/

.signout {
  color: #E5F4E4;
  height: 27px;
  margin-right: 35px;
}


.support {
  height: 1.2em;
  width: 1.2em;
}

.profile {
  color: #E5F4E4;
  height: 27px;
  margin-right: 29px;
}

.user-iconframe {
  position: static;
width: 56px;
height: 56px;
left: 414px;
top: 0px;


/* Inside auto layout */

flex: none;
order: 3;
flex-grow: 0;
margin: 0px 0px;
}
.user-icon{

  justify-content: flex-end;
  display:flex;
position: absolute;
width: 40px;
height: 40px;
right: 25px;
top: calc(50% - 40px/2);
border: 1px transparent;
font-family: 'Roboto';
font-style: normal;
font-weight: 500;
font-size: 17px;
line-height: 23px;
text-align: center;
letter-spacing: -0.005em;
color: #4D4D4D;

}

.user-icon button:hover {
  cursor: pointer;
}

.alert-icon {
  position: absolute;
  width: 9px;
  top: 13px;
  height: 9px;
  right: 135px;
  top: calc(50% - 9px/2 - 14.5px);
}

.alert-icon-2 {
  position: absolute;
  width: 9px;
  height: 9px;
  right: 85px;
  top: calc(50% - 9px/2 - 14.5px); 
}

.user-menu-container{
  position:absolute;
  display: flex;
  z-index: 10;
  flex-direction: column;
  top: 3.5em;
  min-width: 10em;
  width: auto;
  background-color: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border: 0px solid #474a4d;
  border-radius: 10px;
  padding: 0;
  overflow: hidden;
  transition: height 0.5s ease-in-out;
}

.user-menu-container a {
  text-decoration: none;
}

.notification-menu-container{
  position: fixed;
  right: 50px;
  display: flex;
  z-index: 10;
  flex-direction: column;
  min-width: 10em;
  width: auto;
  background-color: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border: 0px solid #474a4d;
  border-radius: 10px;
  padding: 0;
  overflow: hidden;
  transition: all 0.5s ease-in-out;
  max-height: 40%;
  overflow-y: scroll;
  margin-top: 15px;
}
.user-menu-item{
  cursor: pointer;
  text-decoration: none;

  display: flex;
  height: auto;
  width: auto;

  background-color: #E5F4E4;
  align-items: center;
  border-radius: 4px;
  border-top: 2px solid #d4d4d4;
  padding: 8px;
  padding-block: 0.5em;
  margin: 16px;
  margin-left: 23px;
  margin-right: 23px;

  font-weight: bold;
  font-size: 16px;
  color: #357960;
}
.user-menu-item:hover{
  background-color: #ffffff;
}

.user-menu-profile-container{
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 250px;
  padding: 0em 0.5em;
}

.notification {
  text-decoration: none;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 140%;
  /* or 22px */
  
  align-items: center;
  text-align: center;
  letter-spacing: 0.005em;
  
  color: #4D4D4D;
}

.notification--content {
  padding: 15px 0px;
}

.notification--content:hover {
  background-color: #e6e6e6;

  cursor: pointer;
}

.notification-button {
  border: none;
    background-color: #357960;
    box-sizing: border-box;
    border-radius: 0.5em;
    font-style: normal;
    font-weight: bold;
    color: #ffffff;
    box-shadow: 2px 2px 1px rgb(0 0 0 / 25%);
}

.user-menu-profile-container h1 {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;

  color: #B5B5B5;
  display: flex;
  flex-direction: column;
  align-items: center;
}



.user-account-circle{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 10px 0px;
  /* border: 3px solid #474a4d; */
  border: 5px solid #4D4D4D;
  border-radius: 50%;
  box-sizing: border-box;
  /* height: 2.5em; */
  height: 110px;
  /* width: 2.5em; */
  width: 110px;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 900;
  font-size: 42px;
  line-height: 49px;
  text-align: center;
  letter-spacing: -0.015em;
}

.user-menu-version-info{
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin-top: 6px;
  color: #4D4D4D;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.product-names{
  justify-self: start;
  margin-left: 15px;
}

.product-version-numbers{
  justify-self: end;
  margin-right: 15px;
}

.cancel-button{
  padding: 8px 75px;
  cursor: pointer;
  display: flex;
  height: auto;
  width: auto;
  margin: -7px 23px 8px 23px;
  font-weight: bold;
  font-size: 16px;
}

.divider {
  /* Divider */

  width: auto;
  height: 1px;

  border: 1px solid #E7E7E7;

}

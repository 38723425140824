.website-footer {
  z-index: 1000;
  background-color: #357960;
  /* position: fixed; */
  align-items: center; 
  justify-content: center; 

  position:fixed;
   left:0px;
   bottom:0px;
   height:2.5em;
   width:100%;
  /* display: none;  */
  

  /* TEMPORARY DISPLAY NONE, CANNOT FIX YET BECAUSE WE HAVEN'T STARTED REFACTORING THE OTHER PAGES */
  /* display: none; */


  /* height: 2.5em;
  bottom: 0%;
  left: 0;
  width: 100%;
  z-index: 1000;
  position: absolute; */



} 
  
.website-footer__copyright {
  font-size: 1em;
  font-weight: 300;
  margin-top: 10px;
  color: #ffffff;
}
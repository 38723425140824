.tab-detail-body {
  position: relative;
  background: #DCE1CE;
  border-radius: 30px;
  width: 54em;
  height: 28em;
  margin-top: 2em;
}

.tab-heading {
  display: flex;
  flex-flow: row nowrap;
  align-items: left;
  align-content: center;
  margin: 0;
}

.tab-heading > h1 {
  font-size: 30px;
  line-height: 100%;
  letter-spacing: -0.015em;
  text-decoration-line: underline;
  margin-top: 1em;
  margin-left: 1em;
}

.tab-info-body-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
  height: auto;
}

.tab-info-body {
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 23px;
  letter-spacing: -0.015em;
  color: #3A3A32;
  text-align: left;
  margin-left: 1.3em;
  height: 12em;
  width: 100%;
}

.tab-info-body > p {
  margin-bottom: 0.8em;
  margin-top: 0.4em;
}

.initial-circle {
  border: 2px solid #3A3A32;
  box-sizing: border-box;
  width: 55px;
  height: 55px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  margin-left: 2em;
  margin-top: 1.2em;
}

.initial-circle > p {
  margin: 0;
  font-weight: 500;
  font-size: 25px;
  line-height: 29px;
}

.left-btn {
  all: unset;
  cursor: pointer;
  align-self: center;
  justify-content: center;
  margin-left: 1.5em;
  margin-right: 1.3em;
}

.right-btn {
  all: unset;
  cursor: pointer;
  align-self: center;
  justify-content: center;
  margin-right: 1.5em;
}

.tab-page-footer {
  position: absolute;
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 40px;
  color: #9B9B9B;
  bottom: 1em;
  left: 0; 
  right: 0; 
  margin-left: auto; 
  margin-right: auto; 
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.tab-page-footer b {
  color: #3A3A32 !important;
}

.tab-page-footer > p {
  margin: 0;
  margin-left: 3px;
  margin-right: 3px;
}

.landowner-observations-body {
  margin-right: 1.5em;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  font-size: 23px;
  width: 100%;
  height: 12em;
}

.monitoring-info-body {
  margin-right: 1.5em;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  font-size: 23px;
  width: 100%;
  height: 12em;
}

.new-report-btn {
  background: none;
  border: none;
  justify-self: center;
  margin: auto;
  cursor: pointer;
}

.new-report-btn:hover {
  transform: scale(1.05);
}

.new-report-btn:focus {
  outline: none;
  border: none;
}